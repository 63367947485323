import { Component, OnInit, Output, EventEmitter, AfterViewInit, ChangeDetectorRef, Input } from '@angular/core';
import { CreateDataService } from '../services/create-data.service';
import { map } from 'rxjs/operators'
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-emotion',
  templateUrl: './emotion.component.html',
  styleUrls: ['./emotion.component.scss']
})
export class EmotionComponent implements OnInit, AfterViewInit {

  @Input() existingEmotionData: [];
  @Input() existingTitle: [];
  // @Input() myCommonArrEmotions: [];
  @Output() emotionData = new EventEmitter<any>();
  @Input() notPas;
  showNext: boolean = false;
  emotionDataArr = [];
  emotionType;
  pageTitle = "Add Past Emotions"
  reqEmotionDataArr = [];
  emotionDataArrCpy = [];
  noEmotionSelected = false;
  emotionsData: any = [];
  clearAll = false;
  currentIndex = 'Column 1';
  emotions: any = ["Column 1", "Column 2", "Column 3", "Column 4", "Column 5", "Column 6", "Column 7", "Column 8", "Column 9", "Column 10", "Column 11"];
  scroll;
  constructor(private readonly createDataService: CreateDataService, public router: Router,
    private readonly cdref: ChangeDetectorRef, private location: Location, private route: ActivatedRoute,) { }


  ngOnInit(): void {
    
    window.scrollTo({top: 0,behavior: "smooth"});
    // console.log(this.myCommonArrEmotions, "myCommonArrEmotions----")
    let getUrl = this.router.url
    let goalId = this.route.snapshot.paramMap.get('id');
    if (getUrl == "/create-goals") {
      this.pageTitle = "Add Future Emotions";
      this.emotionType = "2";
    }
    if (goalId) {
      let updateGoal = "/update-goals/" + goalId
      if (getUrl == updateGoal) {
        this.emotionType = "2";
        this.pageTitle = "Add Future Emotions"
      }
    }
    if (this.notPas == 'true') {
      
      this.pageTitle = "Select Emotions"
    }
    this.showNext = false;
    this.getEmotionData();
  }
  ngAfterViewInit(): void {

  }
  getEmotionData() {
    this.createDataService.getEmotionData().subscribe((res) => {
      this.emotionDataArr = res.data
      this.emotionDataArrCpy = res.data;
      this.emotionsData = res.data.reduce((groups, item) => {
        const group = (groups[item.title] || []);
        group.push(item);
        groups[item.title] = group;
        return groups;
      }, {});
      console.log(this.emotionsData, "this.emotionsData");

      this.emotionDataArr.map((e) => {
        //console.log(e);
        e['checked'] = false;
      })
      if (this.existingEmotionData?.length > 0) {
        this.existingEmotionData.forEach((e: any) => {
          this.emotionDataArr.forEach((e2: any) => {
            if (e.emotion_id == e2.id) {
              e2.checked = true;
            }
          })
        })
       this.prepareGenericEmotionObj();
      }
      this.showNext = true;
      // console.log(this.emotionDataArr);
    },
      (error: HttpErrorResponse) => {
        console.log(error);

      },
      () => {
        this.existingEmotionData.forEach((e: any) => {
          this.emotionDataArr.forEach((e2: any) => {
            if (e.emotion_id == e2.id) {
              e2.checked = true;
            }
          })
        })
      })
    setTimeout(() => {
      this.clearAll = false;
    }, 1000);
  }

  clearAllSelection() {
    if(this.existingEmotionData.length != 0){
      this.existingEmotionData = [];
      localStorage.setItem('clearEmotion', 'clearAll');
    }    
    this.reqEmotionDataArr = [];
    this.getEmotionData();
  }
  goBack() {
      this.emotionData.emit([-1, null]);
  }
  showIntensity() {
    this.prepareGenericEmotionObj();
    console.log('this.reqEmotionDataArr.length',this.reqEmotionDataArr.length)
    if (this.reqEmotionDataArr.length == 0) {
      // this.noEmotionSelected = true;
    } else {
      console.log(this.reqEmotionDataArr, this.existingEmotionData, "this.reqEmotionDataArr")
      this.emotionData.emit([2, this.reqEmotionDataArr]);
    }
  }

  makeDataObjEmotions(data: any) {
    data.checked = !data.checked;
  }

  prepareGenericEmotionObj() {
    this.reqEmotionDataArr = [];
    this.emotionDataArr.forEach((data) => {
      data.cheched = !data.cheched;
      if (data.checked) {
        let newObj = {
          color_code: data.color_code,
          id: data.id,
          name: data.name,
          checked: data.checked
        }
        this.reqEmotionDataArr.push(newObj);
      }
    })
  }
  handleEmotionData(data: any) {
    // if (data[0] == -1) {
    //   this.showEmotion = 0;
    // } else if (data[0] == -2) { 
    //   this.showEmotion = 1;
    // } else {
    //   this.showEmotion = data[0];
    //   this.emotionDataForIntensity = data[1];
    // }
  }
}
