import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-loginheader',
  templateUrl: './loginheader.component.html',
  styleUrls: ['./loginheader.component.scss']
})
export class LoginheaderComponent implements OnInit {
  activeClass:any;
  constructor( private readonly router: Router,) { }
  ngOnInit(): void {
    let  checkUrl= this.router.url;
    let getUrl = checkUrl.split("?");
    if(getUrl[0]==="/terms-conditions"){
      this.activeClass=1;
    }
    if(getUrl[0]==="/privacy-policy"){
      this.activeClass=2;
    }
    if(getUrl[0]==="/faq"){
      this.activeClass=3;
    }
    if(getUrl[0]==="/contactus"){
      this.activeClass=4;
    }
    if(getUrl[0]==="/feedback"){
      this.activeClass=5;
    }
    console.log(this.activeClass, "this.activeClass==")
   
  }
  gotoFaq() {
    this.router.navigate([`/faq`], { queryParams: { 'web': '1' } });
  }
  gotoPrivacy() {
    this.router.navigate([`/privacy-policy`], { queryParams: { 'web': '1' } });
  }
  gotoTerms() {
    this.router.navigate([`/terms-conditions`], { queryParams: { 'web': '1' } });
  }

}
