import { Component, OnInit, ViewChild, NgModule, TemplateRef } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { HeaderSearch } from '../../../services/headerSearch.service';
import { MaintainDataService } from '../../../dashboard/create-entry/services/maintain-data.service';
import { AdminService } from '../../../services/admin.service';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DataShareService } from '../../../services/data-share.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { NotificationService } from 'src/app/dashboard/notification/notification.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'gd-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public userData: any;
  public showSearch: boolean = false;
  public deanAccess: boolean = false;
  searchText = '';
  closeSearch = false;
  callSearch: any;
  searchBarForm: FormGroup = null;
  baseUrl: string = environment.backend.baseURL;
  currenturl: any;
  show = true;
  @ViewChild('myDrop') myDrop: NgbDropdown;
  avatarColor = 3;
  avatarImage = 3;
  status = 0;
  showGoalButton = false;
  showGdButton = false;
  notification: any;
  admin1Access: boolean = false;
  modalRef: BsModalRef;
  profileData;
  previousProUser_type;
  previousProRole;
  companyTitle: string = '';
  report3 = '';
  allCompanyData: any = [];
  searchGoalTxtCol = '';
  searchGoalTxt = '';
  @ViewChild('roleChange', { static: true }) roleChange: TemplateRef<any>;
  isSubjectRes: boolean;
  subscribe: Subscription;
  date= new Date();
  private notificationUnsubscribe: any;

  constructor(private readonly authService: AuthService, public router: Router, private headerSearch: HeaderSearch, private mainDataService: MaintainDataService, private dataSharingService: DataShareService, public api: AdminService,
    private popupService: BsModalService, private notificationService: NotificationService
  ) {
    this.dataSharingService.isProfileChanged.subscribe(value => {
      this.userData = JSON.parse(localStorage.getItem('gdUserInfo'));
      this.getImageDetails();

    });

    if (this.userData?.is_root_admin) {
      this.companyTitle = localStorage.getItem('companyTitle')
      this.companyTitle = this.companyTitle.toString().replace(/"/g, "");
      this.getCompanyList();
    }
    this.dataSharingService.isNotification.subscribe(value => {
      if (value) {
        this.getnotificationCount();
      }
    });
  }

  ngOnInit(): void {
    let count = 1;
    this.router.events.forEach((event: any) => {
      if (event instanceof NavigationEnd) {
        let token = localStorage.getItem('access_token')
        if ((!event.url.includes('/play-audio/') && !event.url.includes('/login/') && !event.url.includes('/team-management/edit_details')) && token) {
          if (count === 1) {
            this.getProfile(this.router.url)
          }
          count++        
        }
      }
    });

    this.userData = JSON.parse(localStorage.getItem('gdUserInfo'));
    if (count === 1) {      
      this.getProfile(this.router.url)
    }
    this.searchBarForm = new FormGroup({
      firstName: new FormControl('', [])
    });
    this.getImageDetails();
    this.showSearch = this.headerSearch.ShowSearch;
    try {
      this.headerSearch.receivedFilter.subscribe((showSearch: boolean) => {
        this.showSearch = showSearch;
        this.searchText = '';
        this.callSearch = this.headerSearch.SearchMethod;

      });
    } catch (e) { }
    this.getnotificationCount();
  }
  getNotification() {
    this.getnotificationCount();
  }

  createEntry(type) {
    localStorage.setItem('lastRoute', this.router.url);
    let data = {
      access_token: localStorage.getItem('access_token'),
    };

    this.mainDataService.createDataForEntry(null, false)
    this.api.postData(`${this.baseUrl}/api/v1/vaults/entries_count`, data)
      .subscribe(async (res) => {
        if (res.data.length == 0) {
          if (type === 1) {
            this.mainDataService.createDataForEntry(null, false)
            localStorage.setItem('lastRoute', this.router.url);
            this.router.navigate(['/growth-driver']);
          }
          if (type === 3) {
            this.mainDataService.createDataForEntry(null, false)
            localStorage.setItem('lastRoute', this.router.url);
            this.router.navigate(['/create-goals']);
          }
          if (type === 4) {
            this.mainDataService.createDataForEntry(null, false)
            localStorage.setItem('lastRoute', this.router.url);
            this.router.navigate(['/create-project']);
          }
        }
        let goal = false;
        let gd = false;
        let project = false;
        res.data.map((item) => {
          if (type === 1) {
            if (item.vaults_typeid == '1') {
              gd = true;
              if (item.count < 201) {
                this.mainDataService.createDataForEntry(null, false)
                localStorage.setItem('lastRoute', this.router.url);
                this.router.navigate(['/growth-driver']);
              } else {
                this.api.errorToast('', 'You have reach the limit ');
              }
            }
          }
          if (type === 3) {
            if (item.vaults_typeid == '3') {
              goal = true;
              if (item.count < 51) {
                this.mainDataService.createDataForEntry(null, false)
                localStorage.setItem('lastRoute', this.router.url);
                this.router.navigate(['/create-goals']);
              } else {
                this.api.errorToast('', 'You have reach the limit ');
              }
            }
          }
          if (type === 4) {
            if (item.vaults_typeid == '4') {
              project = true;
              if (item.count < 51) {
                this.mainDataService.createDataForEntry(null, false)
                localStorage.setItem('lastRoute', this.router.url);
                this.router.navigate(['/create-project']);
              } else {
                this.api.errorToast('', 'You have reach the limit ');
              }
            }
          }
        });
        if (!gd && type === 1) {
          this.mainDataService.createDataForEntry(null, false)
          localStorage.setItem('lastRoute', this.router.url);
          this.router.navigate(['/growth-driver']);
        } if (!goal && type === 3) {
          this.mainDataService.createDataForEntry(null, false)
          localStorage.setItem('lastRoute', this.router.url);
          this.router.navigate(['/create-goals']);
        }
        if (!project && type === 4) {
          ;
          this.mainDataService.createDataForEntry(null, false)
          localStorage.setItem('lastRoute', this.router.url);
          this.router.navigate(['/create-project']);
        }
      })
  }

  openDropDown() {
    if (this.status == 1) {
      this.myDrop.close();
      this.status = 0;
    } else {
      this.myDrop.open();
      this.status = 1;
    }
  }

  getnotificationCount() {
    this.api.showLoader = true;
    let data = {
      'access_token': localStorage.getItem('access_token')
    }
    this.api.getData(`${this.baseUrl}/api/v1/notifications`, data).subscribe(async (res) => {
      let count = res.data.newCount ? res.data.newCount : 0;
      this.notification = count;
      this.api.showLoader = false; 
    },
      (error: HttpErrorResponse) => {
        console.log(error,'error');
      });
  }


  logout() {
    this.authService.logout(0);
    // this.myDrop.close();
  }
  public setSearchBar(showSearch: boolean, cb) {
    this.showSearch = showSearch;
    this.callSearch = cb;
  }
  refreshPage() {
    let base = this;
    this.closeSearch = false;
    this.searchText = '';
    setTimeout(function () {
      let lastValue = localStorage.getItem('searchString');
      base.headerSearch.SearchMethod("");
    }, 300);
  }
  searchItem(searchValue: string) {
    let base = this;
    localStorage.setItem('searchString', searchValue);
    if (this.showSearch) {
      setTimeout(function () {
        let lastValue = localStorage.getItem('searchString');
        if (lastValue == searchValue) {
          base.headerSearch.SearchMethod(searchValue);
        }
      }, 900);
    }
    if (searchValue.length > 0) {
      this.closeSearch = true;
    }
    else {
      this.closeSearch = false;
    }

  }
  getImageDetails() {
    if ((this.userData.avatar_id) && (this.userData.avatar_id.length > 0) && (this.userData.avatar_id > 0)) {
      this.avatarImage = this.userData.avatar_id;
    }

    if ((this.userData.avatar_color) && (this.userData.avatar_color.length == 1)) {
      this.avatarColor = this.userData.avatar_color;
    }
    if ((this.userData.avatar_color) && (this.userData.avatar_color.length == 7)) {
      switch (this.userData.avatar_color) {
        case '#FF6969':
          this.avatarColor = 1;
          break;
        case '#B865CF':
          this.avatarColor = 2;
          break;
        case '#44CF7B':
          this.avatarColor = 3;
          break;
        case '#7465CF':
          this.avatarColor = 4;
          break;
        case '#FFB341':
          this.avatarColor = 5;
          break;
      }
    }
  }
  toggleNavbar(event: any) {
    let body = document.getElementsByTagName('body')[0];
    if (this.show == true) {
      body.classList.add("sidebar-mini");
      this.show = false;
    } else {
      body.classList.remove("sidebar-mini");
      this.show = true;
    }
  }

  gotoProfile() {
    localStorage.setItem('settingTab', "profile");
    this.router.navigate(['/settings'])
  }

  getProfile(url: string): void {
    const profileObj = {
      access_token: localStorage.getItem('access_token'),
    };
    this.api.postData(`${this.baseUrl}/api/v1/user/profile`, profileObj).subscribe(async (res) => {
      console.log("aaaaa=================")
      this.profileData = res;
      let role = localStorage.getItem('role')
      if (role) {
        let role = JSON.parse(localStorage.getItem('role'));
        let user_type = JSON.parse(localStorage.getItem('user_type'));
        this.previousProRole = role;
        this.previousProUser_type = user_type;
        if (role == res.data.role && user_type == res.data.user_type) {
          this.changeRole(res, url);
        } else {
          this.openDialog(this.roleChange);
        }
      } else {
        this.changeRole(res, url);
      }
    },
      (error: HttpErrorResponse) => {
        console.log(error, 'error');
      })
  }

  getCompanyList() {
    let params = new HttpParams();
    params = params.append('access_token', localStorage.getItem('access_token'));
    if (this.searchGoalTxt != null && this.searchGoalTxt != '') {
      params = params.append('query', this.searchGoalTxt);
    }
    if (this.subscribe) {
      this.subscribe.unsubscribe();
    }
    this.subscribe = this.api.getData(`${this.baseUrl}/api/v2/root_admins/get_company_lists?access_token=`, params).subscribe(res => {
      this.allCompanyData = res.data
      this.isSubjectRes = true;
    })
  }

  changeRole(res, url) {
    if (((res.data.user_type == '1') || ((res.data.user_type) == '2')) && res.data.role == '1') {
      this.deanAccess = true;
    } else {
      this.deanAccess = false;
    }
    if (((res.data.user_type) == '2') && res.data.role == '2') {
      this.admin1Access = true;
    } else {
      this.admin1Access = false;
    }
    // if (url == '/admin-management' && this.deanAccess && !this.admin1Access) {
    //   // this.router.navigate([url])
    // }
    // if ((this.deanAccess || this.admin1Access) && (url != '/admin-management')) {
    //   // this.router.navigate([url])
    // }
  }

  roleChanges() {
    this.modalRef.hide();
    this.changeRole(this.profileData, this.router.url);
    localStorage.setItem('role', JSON.stringify(this.profileData.data.role))
    localStorage.setItem('user_type', JSON.stringify(this.profileData.data.user_type))
  }

  checkUrl() {
    if (this.router.url.includes('/team-management')) {
      return true;
    } else {
      return false;

    }
  }

  openDialog(template: TemplateRef<any>): void {
    if (this.modalRef) {
      this.modalRef.hide();
    }
    let cssClass = 'modal-dialog w-3';
    this.modalRef = this.popupService.show(template, {
      class: cssClass,
      backdrop: 'static',
    });
  }

  onKeypressEvent(event) {
    this.allCompanyData = []
    this.isSubjectRes = false;
    if (this.searchGoalTxt != '' && this.searchGoalTxt.length > 1) {
      setTimeout(() => {
        this.getCompanyList();
      }, 500)
    } else if (this.searchGoalTxt == '') {
      this.searchGoalTxt == '';
      this.getCompanyList();
    }
  }

  removeGDData() {
    this.api.loader(true);
    this.api.showLoader = true;
    this.searchGoalTxt = '';
    this.getCompanyList();
  }

  selectGD(data, index) {
    this.companyTitle = data.name;
    localStorage.setItem('access_token', data.access_token);
    localStorage.setItem('companyTitle', this.companyTitle);
    if (this.router.url == '/dashboard') {
      this.dataSharingService.isOrganizationChange.next(true);
    } else {
      this.router.navigate(['/dashboard']);
    }
  }

  closeDropdown() {
    if (this.myDrop.isOpen()) {
      this.myDrop.close();
    }
  }

  goToDashbord() {
    if (this.router.url == '/dashboard') {
      window.location.reload();
    } else {
      this.router.navigate(['/dashboard']);
    }
    this.getNotification();
  }
}
