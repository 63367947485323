 export class loginModel {
    email: string = '';
    password: string = '';
    isRemember: boolean = false;
}

export class resetPasswordModel {
    email: string = '';
}

export class generatePasswordModel {
    firstName: string = '';
    lastName: string = '';
    userName: string = '';
    password: string = '';
}