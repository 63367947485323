<div id="myHtmlElement">
    <head>
        <!-- Required meta tags -->
        <meta name="description" content="">
        <meta charset="UTF-8">
        <meta name="author" content="">
        <!-- title -->
        <title>Growth Driver </title>

        <!-- Mobile Specific Metas ============== -->
        <meta name=viewport content="width=device-width, initial-scale=1, user-scalable=no, shrink-to-fit=no">
        <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1">
        <link rel="preconnect" href="https://fonts.googleapis.com">
        <link
            href="https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,300;0,400;0,500;0,600;0,800;0,900;1,200;1,300;1,500;1,600;1,800;1,900&display=swap"
            rel="stylesheet">

        <!-- favicon ============== -->
        <link rel="shortcut icon" type="image/ico" href="images/favicon.ico" />

        <!-- CSS ====== -->
        <style type="text/css">
            * {
                box-sizing: border-box;
                margin: 0px;
                padding: 0px;
            }

            a:hover {
                text-decoration: none;
            }

            img {
                max-width: 100%;
                vertical-align: top;
            }

            button,
            select {
                outline: none;
            }

            .navbar-toggler:focus,
            .navbar-toggler:hover {
                outline: none;
            }

            /* headings */
            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                font-size: 100%;
                font-weight: 600;
                text-transform: capitalize;
                color: #2c2c2c;
                padding: 0 0 10px 0;
            }

            h1 {
                font-size: 30px;
            }

            h2 {
                font-size: 20px;
            }

            h3 {
                font-size: 20px;
            }

            h4 {
                font-size: 16px;
            }

            h5 {
                font-size: 16px;
            }

            h6 {
                font-size: 14px;
            }

            p {
                padding: 0 0 10px 0;
            }

            strong {
                padding: 0 0 10px 0;
                display: block;
            }

            p strong {
                padding: 0 0 10px 0;
                display: inline-block;
            }


            ul[class*="grid-"] {
                margin: 0 -10px -10px;
                display: flex;
                min-width: 100%;
                list-style: none;
            }

            ul.grid-2>li {
                float: left;
                width: 50%;
                padding: 0px 10px 20px 10px;
            }

            ul.grid-3>li {
                float: left;
                width: 33.33%;
                padding: 0px 10px 20px 10px;
            }

            ul.grid-4>li {
                float: left;
                width: 25%;
                padding: 0px 10px 20px 10px;
            }

            /*  .fifty{width: 50%;}*/
            .question {
                margin-bottom: 10px;
                margin-top: 10px;
            }

            .description {
                /*height: calc(200px - 95px);*/
                overflow: auto;
                max-height: 400px;
                padding: 10px;
                resize: none;
                border: 1px solid #C0CBDB;
                height: 200px;
                font-family: 'Poppins', sans-serif;
                border-radius: 4px;
                font-size: 14px;
            }

            .mortgage_details li {
                display: flex;
                align-items: center;
            }

            .detailContent ul li span .semi-bold {
                font-weight: 600;
                padding: 2px 0px;
                color: #2c2c2c !important;
                font-size: 16px !important;
            }

            .pink {
                background: rgb(255, 82, 66);
                opacity: 0.5;
                width: auto;
                padding: 2px 10px;
            }

            .half {
                width: 500px;
                margin: 10px 10px 40px 10px;
                float: left;
            }

            .half h4 {
                margin: 10px 0px;
            }

            .column {
                margin: 10px 0;
                padding: 5px 10px;
                font-size: 12px;
                color: #000;
                width: 200px;
                max-width: 300px;
            }

            .clear {
                clear: both;
            }

            .row {
                margin: 0px -15px;
            }

            .float-left {
                float: left;
            }

            .float-right {
                float: right;
            }

            .align-left {
                text-align: left;
            }

            .align-right {
                text-align: right;
            }

            .align-right:before {
                content: '';
                clear: both;
            }

            .status {
                color: #FFA969;
            }

            .bigtext {
                color: #2D2F39;
                font-weight: 700;
                margin-bottom: 20px;
                margin-top: 10px;
                display: block;
                font-size: 70px
            }

            /*============ header css ========== */


            .container {
                width: 100%;
                margin: 0px auto;
                background: #fff;
            }

            .whiteBox {
                background: #fff;
                box-shadow: 0px 0px 6px #4A68AA33;
                border-radius: 10px;
                padding: 20px;
                margin: 10px 0px;
                margin-bottom: 15px;
            }

            .clientlogo {
                width: 200px;
                display: inline-block;
                margin-bottom: 10px;
            }

            .whiteBox h2 {
                color: #2c2c2c;
                padding: 0px;
            }

            .whiteBox h6 {
                padding: 10px 0px;
                color: #2c2c2c;
                font-size: 16px;
            }

            .contentWrap {
                margin-bottom: 25px;
                padding: 15px;
                background-color: rgba(216, 222, 236, 0.5);
                border-radius: 5px;
            }

            .head {
                padding: 10px 15px;
                background-color: #3C5BA0;
                position: relative;
                width: 90%;
                margin: 15px 0;
            }

            .head:after {
                content: '';
                border-top: 52px solid #3C5BA0;
                border-right: 30px solid transparent;
                left: 100%;
                position: absolute;
                top: 0;
            }

            .detailContent ul li {
                display: table-cell;
                vertical-align: middle;
                padding: 0 16px;
            }

            .detailContent ul {
                display: table;
                table-layout: fixed;
            }

            .grey-text .detailContent ul li {
                border-left: none !important;
            }

            .detailContent ul li:first-child {
                padding-left: 0;
                border-left: none;
            }

            .detailContent ul li:last-child {
                padding-right: 0;
            }

            .bold {
                font-weight: 600;
            }

            .sub_heading h4 {
                font-size: 18px;
                color: #272626 !important;
                text-transform: uppercase;
                font-weight: 600;
                margin-bottom: 10px;
            }

            .text_green {
                color: #5cbe8b !important;
            }

            .common_details h6 {
                font-size: 14px;
                font-weight: 600;
                line-height: 20px;
                color: #9c9c9f;
                margin-bottom: 5px;
            }

            .border_bottom {
                border-bottom: 1px solid #e1e6ef;
            }

            .orange_text {
                color: #ffa969 !important;
            }
            span.greytext { 
                font-size: 14px !important;
            }
            .greytext {
                letter-spacing: 0.15px;
                color: #818E94;
            }

            .round {
                background: rgba(94, 203, 94, 0.15);
                color: #5ECB5E;
                border-radius: 30px;
                padding: 5px 7px !important;
            }

            .green {
                font-weight: 500;
                font-size: 12px;
                border-radius: 30px;
                padding: 5px 7px !important;
            }

            .detailContent ul li.green {
                padding: 5px 10px;
            }

            .detailContent ul li span {
                font-size: 12px;
                color: #818E94;
            }

            .detailContent ul li span .grey-text {
                font-size: 12px;
                color: #818E94;
            }

            .detailContent ul li span:last-child {
                padding-left: 2px;
            }

            .fifty h6 {
                margin-bottom: 10px;
                margin-top: 20px;
            }

            @media(max-width:767px) {

                p {
                    font-size: 14px;
                    padding: 0 0 5px 0;
                }

                strong {
                    font-size: 14px;
                }

                .header {
                    padding: 14px 0px;
                }

                .header .logo img {
                    width: 40px;
                }

                ul[class*="grid-"] {
                    display: inline-block;
                }

                ul.grid-4>li {
                    width: 50%;
                }

                ul.grid-2>li {
                    width: 100%;
                }

                .note {
                    width: 90%;
                }

                .whiteBox {
                    padding: 20px
                }

                .whiteBox h2 {
                    padding: 5px 0px;
                    font-size: 18px
                }

                .whiteBox h3 {
                    padding: 5px 0px;
                    font-size: 17px
                }

                .whiteBox h6 {
                    padding: 5px 0px;
                }

                .boxWrap .contentBox {
                    padding: 20px 10px;
                    min-height: 250px;
                }

                .downloadBtn {
                    padding: 10px 15px;
                    font-size: 14px;
                }

            }

            @media(max-width:767px) {

                .up-estates {
                    font-size: 22px;
                }

            }

            @media(max-width:599px) {

                ul[class*="grid-"] {
                    display: block;
                    width: 100%;
                }

                ul.grid-2>li {
                    width: 100%;
                    float: none;
                }

                .right_disc {
                    text-align: left;
                }

                .whiteBox {
                    padding: 30px;
                }
            }
        </style>
    </head>
    <body class="width:100%;">
        <!-- main  wrapper-->
        <div class="wrapper" id="wrapper" style="width:100%;">
            <!-- main section  -->
            <section class="bodyContainer">
                <div>
                    <div class="whiteBox">
                        <div style="padding:20px 0px;">
                            <a href="javascript" class="clientlogo"><img src="assets/images/Growthdrivers.svg"></a>

                        </div>
                        <h2 style="color:#000;"> Observation for <span class="text-transform: capitalize;">
                            {{showPdf?.subject?.first_name}}
                            {{showPdf?.subject?.first_name}}
                        </span></h2>
                        <div class="detailContent">
                            <ul style="width: 100%; margin: 0;padding: 0;">
                                <li style="width: 33%;">
                                    <p style="padding:0 0 10px 0; margin-top: 10px;"><b>Title</b></p>
                                    <p
                                        style="margin-bottom:10px;color: #333 !important;font-weight: 500 !important; border: 1px solid #C0CBDB;border-radius:4px; padding:10px">
                                        {{showPdf?.title}} </p>
                                </li>

                                <li style="width: 33%;">
                                    <p style="padding:0 0 10px 0; margin-top: 10px;"><b>Function</b></p>
                                    <p
                                        style="margin-bottom:10px;color: #333 !important;font-weight: 500 !important; border: 1px solid #C0CBDB;border-radius:4px; padding:10px">
                                        {{showPdf?.category}} </p>
                                </li>

                                <li style="width: 33%;">
                                    <p style="padding:0 0 10px 0; margin-top: 10px;"><b>Deadline</b></p>
                                    <p
                                        style="margin-bottom:10px;color: #333 !important;font-weight: 500 !important; border: 1px solid #C0CBDB;border-radius:4px; padding:10px">
                                        {{showPdf?.deadline | date: 'MMM dd yyyy'}} </p>
                                </li>
                            </ul>
                            <ul style="width: 100%;">
                                <li>
                                    <p style="padding:0 0 10px 0; margin-top: 10px;"><b>Description</b></p>
                                    <p
                                        style="border: 1px solid #C0CBDB; border-radius: 4px;padding:10px; color:#000;min-height: 100px;margin-bottom: 40px;">
                                        {{showPdf?.description}}</p>
                                </li>
                            </ul>
                        </div>

                        <div style="border: 1px solid #272626; padding: 20px 10px;">
                            <div class="sub_heading">
                                <h4 style="color: #272626 !important; padding: 0px 0px 5px 0px" class="float-left">
                                    Subject
                                    Profile </h4>
                                <div class="clear"></div>
                            </div>

                            <div class="detailContent  mortgage_details ">
                                <ul>
                                    <li>
                                        <span
                                            style="font-weight: 600;padding: 2px 0px;color: #2c2c2c;font-size: 16px;">First
                                            Name: </span>
                                        <span class="greytext">{{showPdf?.subject?.first_name || 'N/A'}}</span>
                                    </li>

                                    <li>
                                        <span
                                            style="font-weight: 600;padding: 2px 0px;color: #2c2c2c; font-size: 16px;">Last
                                            Name: </span>
                                        <span class="greytext">{{showPdf?.subject?.last_name || 'N/A'}}</span>
                                    </li>


                                    <li>
                                        <span
                                            style="font-weight: 600;padding: 2px 0px;color: #2c2c2c;font-size: 16px;">Work
                                            Email: </span>
                                        <span style="text-transform: lowercase !important;" class="greytext smallLetter">{{showPdf?.subject?.email || 'N/A'}}</span>
                                    </li>


                                    <li>
                                        <span
                                            style="font-weight: 600;padding: 2px 0px;color: #2c2c2c;font-size: 16px;">Job
                                            Function: </span>
                                        <span class="greytext">{{showPdf?.subject?.job_function || 'N/A'}}</span>
                                    </li>
                                    <li>
                                        <span
                                            style="font-weight: 600;padding: 2px 0px;color: #2c2c2c; font-size: 16px;">Job
                                            Title: </span>
                                        <span class="greytext">{{showPdf?.subject?.job_title || 'N/A'}}</span>
                                    </li>
                                    <li>
                                        <span
                                            style="font-weight: 600;padding: 2px 0px;color: #2c2c2c;font-size: 16px;">Total
                                            Points: </span>
                                        <span class="greytext">{{showPdf?.subject?.points || 'N/A'}}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="detailContent" style="display:flex;justify-content: space-between;">
                           
                            <div class="fifty">
                                <h6 style="font-size: 18px;margin-bottom:0.5rem;margin-top: 20px;">Avg. Intensities
                                    Per Emotion</h6>
                                <ul style="margin: 0px;padding:0px; list-style:none;display: block;">
                                    <li *ngFor="let item of showPdf?.avg_tntensities_per_emotion;let i = index;"
                                        class="green" [class.round]="i==0"
                                        style="padding: 0 7px; margin-bottom: 5px;display:flex;justify-content:space-between;font-weight: 500;font-size: 12px;margin-bottom: 5px;">
                                        <span> {{item?.name}}</span> <span>{{item?.avg | number : '1.2-2'}}</span>
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <h6 style="font-size: 18px;margin-bottom:0.5rem;margin-top: 20px;">Emotion
                                    Experienced</h6>
                                <h5
                                    style="color: #2D2F39;font-weight: 700;margin-bottom: 20px;margin-top: 10px;display: block;font-size: 70px">
                                    {{showPdf?.emotion_experienced_count}}</h5>
                            </div>
                            <div class="fifty">
                                <h6 style="font-size: 18px;margin-bottom:0.5rem;margin-top: 20px;">Top 5 Emotions
                                    Experienced</h6>
                                <ul style="margin: 0px;padding:0px; list-style:none;display: block;">
                                    <li *ngFor="let item of showPdf?.top_five_emotions_experienced;let i =index;"
                                        class="green" [class.round]="i==0"
                                        style="padding: 0 7px; margin-bottom: 5px;display:flex;justify-content:space-between;font-weight: 500;font-size: 12px;border-radius: 30px;margin-bottom: 5px;">
                                        <span>{{item?.name}}</span> <span>{{item?.count}}</span>
                                    </li>
                                </ul>
                            </div>

                            <div class="fifty">
                                <h6 style="font-size: 18px;margin-bottom:0.5rem;margin-top: 20px;">Top Emotional
                                    Columns Selected</h6>
                                <div *ngFor="let item of showPdf?.top_emotional_solumns_selected"
                                    [ngStyle]="{'background':item?.color_code}"
                                    style="background: rgb(48, 182, 221);margin:10px 0;padding:5px 10px;font-size: 12px;color: #000;display: flex;justify-content: space-between;">
                                    <span>{{item?.title}}</span><span class="float-right">{{item?.count}}</span>
                                </div>
                            </div>




                        </div>

                        <!-- feedback  -->
                        <div *ngFor="let items of showPdf?.observation_respondents;let index = index;">
                            <h3 style="margin-top:30px;"> Feedback {{index+1}}</h3>
                            <div *ngFor="let data of items?.observation_responses;let i = index;" class="detailContent">
                                <h4 class="question">{{i + 1}})
                                    {{replaceWithName(data?.observation_form)}}</h4>
                                <div style="display:flex;justify-content:center;">
                                    <div class="half">
                                        <h4>Emotions Experienced ({{data?.observation_response_emotions?.length}})</h4>
                                        <div class="description">
                                            <p style="margin-bottom: 5px;"
                                                [ngStyle]="{'background':datas?.emotion?.color_code, 'opacity': opacityValue(datas?.intensity)}"
                                                class="pink" *ngFor="let datas of data?.observation_response_emotions">
                                                {{datas?.emotion?.name}}
                                                ({{datas?.intensity}})</p>
                                        </div>
                                    </div>



                                    <div class="half">
                                        <h4>Comment</h4>
                                        <div class="description">
                                            <p>{{data?.description}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="clear"></div>
                            </div>
                        </div>

                        <!-- <footer class="footer" style="padding:10px 0px; color:#ff5242;;">
                            <div>
                                <div class="right_disc" style="text-align:left;">
                                    <p style="padding:0 0 10px 0;">Growthdrivers Release 2.0.0 © 2025 Guidewise</p>
                                </div>
                            </div>
                        </footer> -->
                    </div>
                </div>
            </section>
        </div>
    </body>
</div>