import { AfterViewInit, Component, Input, OnChanges, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit {
  @Input() index: number;
  @Input() intensityData: any;
  @Input() emotionType: string;
  @Input() forEditIntensity: any;
  myValue = 5.0;
  dayCount = 0;
  hourCount = 0;
  minuteCount = 0;
  defaultValue = 5;
  constructor(public router: Router, private route: ActivatedRoute,) { }

  ngOnInit(): void {
    console.log("00000")
    let getUrl = this.router.url
    let goalId = this.route.snapshot.paramMap.get('id');
    if (getUrl == "/create-goals") {

      this.emotionType = "2"
    }
    if (goalId) {
      let updateGoal = "/update-goals/" + goalId
      if (getUrl == updateGoal) {
        this.emotionType = "2"
      }
    }

    console.log(this.intensityData, "intensityData");
    console.log(this.forEditIntensity, "this.forEditIntensity");
    if (this.forEditIntensity.length != 0) {
      setTimeout(() => {
        for (let i = 0; i < this.forEditIntensity.length; i++) {
          if (Number(this.forEditIntensity[i].emotion_id) === Number(this.intensityData.id)) {
            this.myValue = this.forEditIntensity[i].intensity;
            this.dayCount = this.forEditIntensity[i].day 
            this.hourCount = this.forEditIntensity[i].hours 
            this.minuteCount = this.forEditIntensity[i].minutes 
          }
        }
      }, 1000)
    }

  }

  dayChanged(event: any) {
    if (event) {
      if (event < 0) {
        this.dayCount = 0;
      }
      if (event > 999) {
        this.dayCount = 999;
      }
    } else {
      this.dayCount = 0;
    }
  }
  hourChanged(event: any) {
    if (event) {
      if (event < 0) {
        this.hourCount = 0;
      }
      if (event > 23) {
        this.hourCount = 23;
      }
    } else {
      this.hourCount = 0;
    }
  }
  minuteChanged(event: any) {
    if (event) {
      if (event < 0) {
        this.minuteCount = 0;
      }
      if (event > 59) {
        this.minuteCount = 59;
      }
    } else {
      this.minuteCount = 0;
    }
  }

  formatLabel(value: number) {
    let result = (value - Math.floor(value)) !== 0;
    if (result) {
      return value;
    } else {
      return value + '.0';
    }
  }

  getSliderValue(dat: any) {
    let result = (dat.value - Math.floor(dat.value)) !== 0;
    if (result) {
      this.myValue = dat.value;
    } else {
      let value = dat.value;
      value = value + '.0'
      this.myValue = value;
    }
  }

  dayInput(event: any) {
    if (!event.target.value) {
      this.dayCount = 0;
      return false;
    }
    if ((+event.target.value >= 48) && (+event.target.value <= 999)) {
      console.log(event.target.value);
      this.dayCount = +event.target.value;
    }
    if (this.dayCount < 0) {
      this.dayCount = 0;
    }
    if (this.dayCount > 999) {
      this.dayCount = 999;
    }
  }
  hourInput(event: any) {
    if (!event.target.value) {
      this.hourCount = 0;
      return false;
    }
    if ((+event.target.value >= 48) && (+event.target.value <= 23)) {
      this.hourCount = +event.target.value;
    }
    if (this.hourCount < 0) {
      this.hourCount = 0;
    }
    if (this.hourCount > 23) {
      this.hourCount = 23;
    }
  }
  minuteInput(event: any) {
    if (!event.target.value) {
      this.minuteCount = 0;
      return false;
    }
    if ((+event.target.value >= 48) && (+event.target.value <= 59)) {
      this.minuteCount = +event.target.value;
    }
    if (this.minuteCount < 0) {
      this.minuteCount = 0;
    }
    if (this.minuteCount > 59) {
      this.minuteCount = 59;
    }
  }

}
