import { Injectable } from '@angular/core';
import { AdminService } from './admin.service';
declare var webkitSpeechRecognition: any;
declare var InstallTrigger: any;

@Injectable({
  providedIn: 'root'
})
export class VoiceRecognitionServiceService {
  isFirefox = typeof InstallTrigger !== 'undefined';
  recognition = this.isFirefox ? '' : new webkitSpeechRecognition();
  isStoppedSpeechRecog = false;
  public text: string = '';
  tempWords;

  constructor(public api: AdminService) {


  }

  init() {
    if (this.isFirefox) {
      this.api.errorToast('', 'browser not supporting');
      return false;
    } else {
      this.permission();
      this.recognition.interimResults = true;
      this.recognition.lang = 'en-US';
      this.recognition.addEventListener('result', (e) => {
        const transcript = Array.from(e.results)
          .map((result) => result[0])
          .map((result) => result.transcript)
          .join('');
        this.tempWords = transcript;
      });
      return true;
    }
  }

  permission() {
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(function (stream) {
        console.log('You let me use your mic!')
      })
      .catch(function (err) {
      });
  }

  start() {
    if (this.isFirefox) {
      this.api.errorToast('', 'browser not supporting');
    } else {
      this.isStoppedSpeechRecog = false;
      this.recognition.start();
      this.recognition.addEventListener('end', (condition) => {
        if (this.isStoppedSpeechRecog) {
          this.recognition.stop();  
        } else {
          this.wordConcat()
          this.recognition.start();
        }
      });
    }
  }
  stop() {
    this.isStoppedSpeechRecog = true;
    this.wordConcat()
    this.recognition.stop();

  }

  wordConcat() {
    this.text = this.text?.replace('undefined', '')
    this.tempWords = this.tempWords?.replace('undefined', '')
    this.text = this.text + ' ' + this.tempWords;
    if (this.text == "undefined" || this.text == undefined) {
      this.text = '';
    }
    this.tempWords = '';
  }
}
