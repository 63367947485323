import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AdminService } from '../../../services/admin.service';


@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
text: string = " "
  constructor(private spinner: NgxSpinnerService, private admin: AdminService) {
  }

  ngOnInit(): void {
    this.admin.loaderText.subscribe(res=>{
      if(res) {
        this.text = res;
      }
    })
    this.admin.loaderState.subscribe(data => {
      if (data) {
        this.spinner.show();
      }
      else { 
        setTimeout(() => {
          this.spinner.hide();
        }, 500);
        
      }
    });
  }

}
