<div class="wrapper login-section">
    <!-- ==== loginHeader ===== -->
    <div class="loginHeader">
        <div class="container">
            <app-loginheader></app-loginheader>
        </div>
    </div>

    <!-- loginHeader end  -->
    <div class="main_container mb-4">
        <div class="container">
            <div class="profileWrap">
                <h2 style="font-size: 26px;"> Welcome to Growthdrivers!</h2>
                <!-- <div class="logo"><a ><img src="assets/images/Growthdrivers.svg"></a></div> -->
                <div class="form-box">
                    <p>Please complete the fields below to create your Growthdrivers account.</p>


                    <form [formGroup]="form" (ngSubmit)="generatePassword()">
                        <div class="form-group">
                            <label>*First Name</label>
                            <input type="text" class="form-control" formControlName="firstName"placeholder="Enter first name..." id="firstName" maxlength="20">
                            <div class="text-danger" *ngIf="form.controls['firstName'].hasError('required') && showError">
                                *Please enter first name.
                            </div>
                            <div class="text-danger" *ngIf="form.controls['firstName'].hasError('minlength') && showError">
                                *Name needs to be between 1-20 characters.
                            </div>
                            <div class="text-danger" *ngIf="form.controls['firstName'].hasError('maxlength') && showError">
                                *Name needs to be between 1-20 characters. .
                            </div>
                            <div class="text-danger" *ngIf="form.controls['firstName'].hasError('pattern') && showError">
                                *First name cannot include numbers or special characters.
                            </div>
                        </div>
                        <div class="form-group">
                            <label>*Last Name</label>
                            <input type="text" class="form-control" formControlName="lastName" placeholder="Enter last name..." id="lastName" maxlength="20">
                            <div class="text-danger" *ngIf="form.controls['lastName'].hasError('required') && showError">
                                *Please enter last name.
                            </div>
                            <div class="text-danger" *ngIf="form.controls['lastName'].hasError('maxlength') && showError">
                                *Name needs to be between 1-20 characters. .
                            </div>
                            <div class="text-danger" *ngIf="form.controls['lastName'].hasError('pattern') && showError">
                                *Last name cannot include numbers or special characters.
                            </div>
                            <div class="error"></div>
                        </div>



                        <div class="form-group">
                            <label>*Username</label>
                            <input type="text" class="form-control" formControlName="userName" name="userName" placeholder="Create username..." id="userName">
                            <div *ngIf="form.get('userName').invalid && (form.get('userName').dirty || form.get('userName').touched)">
                                <small class="error" *ngIf="form.get('userName').errors?.required">Username is required.</small>
                                <small class="error" *ngIf="form.get('userName').errors?.pattern">*Username cannot start with a dot or space.</small>
                            </div>
                            <div class="text-danger" *ngIf="form.controls['userName'].hasError('required') && showError">
                                *Please enter desired username.
                            </div>
                            <div *ngIf="form.controls['userName'].hasError('pattern') && showError" class="text-danger"> *Username cannot start with a dot or space. 
                            </div>
                            <div *ngIf="form.controls['userName'].hasError('minLength') && showError" class="text-danger"> *Please enter minimum 6 character.
                            </div>
                        </div>

                        <div class="form-group">

                            <label>*Password</label>
                            <input [type]="isTextFieldType1 ? 'text' : 'password' " class="form-control" formControlName="password" name="pwds" placeholder="Create password..." autocomplete="off">
                            <a class="showPass" (click)="togglePasswordFieldType(1)">
                                <img [src]="isTextFieldType1 ? 'assets/images/ic_eye_show_red.svg' : 'assets/images/eye-off-2.svg' " tabindex=3 />
                            </a>
                            <div class="text-danger" *ngIf="form.controls['password'].hasError('required') && showError">
                                *Please enter password.
                            </div>
                            <div class="text-danger" *ngIf="form.controls.password.value && form.controls.password.errors?.minlength&&form.controls.password.errors?.maxlength || form.controls['password'].hasError('pattern')">
                                Password must be 8 to 16 alphanumeric upper and lower case characters in length and contain at least one special character.
                            </div>
                        </div>
                        <div class="form-group">
                            <label>*Confirm Password</label>
                            <input [type]="isTextFieldType2 ? 'text' : 'password' " class="form-control" formControlName="confirmPassword" placeholder="Confirm password..." autocomplete="off" [(ngModel)]="confirmPassword">
                            <a class="showPass" (click)="togglePasswordFieldType(2)">
                                <img [src]="isTextFieldType2 ? 'assets/images/ic_eye_show_red.svg' : 'assets/images/eye-off-2.svg' " tabindex=3 />
                            </a>
                            <div class="text-danger" *ngIf="form.controls['confirmPassword'].hasError('required') && showError">
                                *Please enter confirm password.
                            </div>
                            <div class="text-danger" *ngIf="form.controls.password.value && form.controls['confirmPassword'].hasError('mustMatch')">
                                *Please make sure your passwords match.
                            </div>
                        </div>

                        <label class="acceptPolicy" for="checkPolicy"><input id="checkPolicy" type="checkbox" value="accept" formControlName="acceptPolicy"> By checking this box, you agree to our <a href="/privacy-policy" target="_blank">Privacy Policy</a> as well as our <a href="/terms-conditions" target="_blank">Terms of Use</a></label>
                        <div class="form-group create_btn_margin">
                            <button type="submit" class="btn btn-primary create_btn" [disabled]="!form?.valid" [class.disable]="!form?.valid"><span></span>CREATE ACCOUNT</button>
                        </div>
                        <p class="mb-0" style="font-size: 13px;">For additional assistance, please contact our support team at
                            <a class="hyperlink" href="mailto:support@guidewise.io">support&#64;guidewise.io.</a>
                        </p>
                    </form>

                </div>
            </div>
        </div>
    </div>
    <div class="footerWrap">
        <div class="copyright">
            <a class="_logo_a mt-2" href="https://www.guidewise.io/" target="_blank" rel="noopener noreferrer">
                <img class="_logo" src="../../../assets/images/guidewise_primary mark_final.jpg">
            </a>
            <span>Growthdrivers R3.0.0</span>
            <p>&copy; 2021 - 2025 Guidewise</p>
        </div>
    </div>
</div>
<ng-template #modalSave>
    <div class="modal-header d-flex align-items-center justify-content-center">
        <figure class="m-0"><img src="assets/images/ic_accs_black.svg"></figure>
        <h5 class="modal-title">Oops!</h5>
    </div>
    <div class="modal-body">
        <p>This link has expired.</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="common-btn " (click)="modalRef.hide();goToLogIn()"> OKAY </button>
    </div>
</ng-template>