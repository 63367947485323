import { Injectable } from '@angular/core';
import { InProgressDetails } from '../../pages/in-progress/in-progress-details/models/in-progress-details';

@Injectable({
  providedIn: 'root'
})
export class MaintainDataService {

  editData = {};
  isEdit = false;
  constructor() { }

  createDataForEntry(data: any, isEdit: boolean) {
    this.isEdit = isEdit;
    if (data) {
      if (data.data) {
        this.editData = data.data;
      } else {
        this.editData = data;
      }
    } else {
      this.editData = {};
    }
  }
  clearData() {
    this.editData = {};
    this.isEdit = false;
  }
}
