<div class="wrapper login-section">
    <!-- ==== loginHeader ===== -->
    <div class="loginHeader">
        <div class="container">
            <div class="row">
                <div class="col-6">
                    <a  routerLink="/" class="logo">
                        <img src="assets/images/Growthdrivers.svg" />
                    </a>
                </div>
                <div class="col-6">
                    <ul class="navbar">
                        <!-- <li><a target="_blank" href="http://35.154.162.195/growthdriver01122020/faq-page/help.html"> Help </a></li> -->
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <!-- loginHeader end  -->
    <div class="main_container terms">
        <div class="container">  
            <h1>ENROLLMENT AGREEMENT <span> Last updated: October 16, 2021 </span></h1>
            <p> Enrollment Agreement (“Agreement”) between T.G. and G.T Wolf, Inc. d/b/a The School of Biz© (“TSB”) and ___________________________________________________________________ (“Client”). </p>


            <p><strong>1. Enrollment</strong>. Client wishes to enroll in the TSB educational program consisting of a series of online courses and programming known as “Elite Entrepreneur Incubator” (the “Program”). This Agreement governs the terms of use
                by the Client of the materials and information provided by TSB to the Client (which shall include, for the purposes of this Agreement, its registered employees who attend the Program). The Client and its registered employees shall be required
                to enroll online through TSB’s website registration system and to pay all applicable fees for the Program as set forth on TSB’s website at <a href="https://www.theschoolofbiz.com/">https://www.theschoolofbiz.com/</a> (the “Site”)</p>

            <p><strong>2. System and Materials, Ownership of Proprietary Information</strong>. The Program will provide students with a series of written materials, forms, formulas, ideas, business systems, exercises to be completed, personal questionnaires
                and evaluations, methodologies, tools, documentation, know-how, show-how, programs, software, trade secrets, and other proprietary information developed and presented by TSB (collectively, the “Course Materials”). Included in the Course
                Materials is a software application called Growthdrivers1 and the form and contents of such software application. In addition, the Client understands that TSB has developed and is the sole owner of the Course Materials (all, collectively,
                “TSB Proprietary Information”). TSB is the exclusive owner of the copyright to any written materials included within the Course Materials. Client expressly acknowledges the provisions of this Section 2 and further acknowledge that the
                Course Materials are the sole property of TSB and that this contract does not authorize Client to re-use or distribute the Course Materials. </p>

            <p> <strong>3. Options and Fees</strong>. A description of the Program is contained on the TSB website and in any program policies provided to Client by TSB during its enrollment (“Program Policies”). Program Policies may include descriptions
                of the schedule for presentations and follow up evaluations, different course options and tiers of fees. Program Policies are incorporated herein and made a part of this Agreement. </p>


            <p> <strong>4. Client Responsibilities, Indemnification: </strong> Client acknowledges that: (a) it is solely responsible for implementing any or all parts of the Program and for all use of the Site, the Program and all Course Materials and other
                content provided by TSB; (b) it chooses in its sole discretion, the information and content to implement for its use; and (c) that TSB is not responsible for the outcome of any implementation or use by the Client. Client shall indemnify
                TSB against any and all costs and damages, including reasonable attorney fees, that may arise from the result of the items described in this Section 4 and for any breach by Client of the terms of this Agreement.</p>


            <p><strong>5. Term and Termination</strong>. The Client and its employees shall have access to the Program for the term of their enrollment and so long as they shall have paid all applicable enrollment fees. The provisions of Sections 3 through
                10 shall continue indefinitely even after enrollment in the Program has ceased. </p>

            <p><strong>6. Assignment:</strong> Client may not assign this Agreement without the express written consent of TSB. TSB may freely assign this Agreement. This Agreement shall be binding upon and inure to the benefit of the successors and permitted
                assigns of the parties. </p>

            <p> <strong>7. Severability of Provisions:</strong> If any provision of this Agreement is held invalid, illegal, or unenforceable by a court of competent jurisdiction, all remaining provisions of this Agreement shall continue in full force and
                effect. The prevailing party in any action at law or in equity to enforce or interpret the provisions of this Agreement shall be entitled to reasonable attorney’s fees in addition to any other relief that may be available. </p>


            <p><strong>8. Privacy and Terms of Use </strong>. By signing this Agreement, Client acknowledges that it has read and agrees to the terms of TSB’s Privacy Policy located at <a href="https://www.theschoolofbiz.com/privacy-policy">https://www.theschoolofbiz.com/privacy-policy</a>                and attached hereto as Exhibit A, and TSB’s Terms of Use Agreement located at
                <a href="https://www.theschoolofbiz.com/terms-of-use">https://www.theschoolofbiz.com/terms-of-use </a> and attached hereto as Exhibit B. The Privacy Policy and Terms of Use Agreement are incorporated herein by reference; provided that
                to the extent of any conflict between this Agreement and the Terms of Use Agreement, this Agreement shall control.</p>


            <p><strong>9. General: </strong></p>

            <p> (a) TSB is an independent contractor and not an employee or agent of the Client. The Client will not be liable for any payments normally made by an employer such as employment taxes, workmen’s compensation, or any other tax associated with
                employment. No employee benefit plan or program of the Client will apply to TSB. </p>

            <p>(b)This Agreement contains the entire agreement between the parties. This Agreement supersedes any and all prior agreements, understandings and proposals, written or oral, between the parties with respect to the subject matter. This Agreement
                may not be waived, or modified except by written agreement of the parties. </p>

            <p> (c)TSB, shall be entitled to an injunction, preliminary injunction, temporary restraining order, or other form of equitable relief restraining such breach or violation, and to exercise all other legal and equitable remedies afforded to it
                by law, and to recover from Client reasonable costs and attorneys’ fees incurred by TSB in exercising any such remedy. </p>

            <p> (d)Client represents that it has the power and authority to enter into this Agreement and that this Agreement constitutes its valid and binding obligation. </p>

            <p> (e)The Agreement shall be interpreted in accordance with and governed by the substantive laws of the Commonwealth of Pennsylvania, without regard to any principles of conflicts of law thereof. The courts of Pennsylvania shall have the exclusive
                jurisdiction in any controversy relating to or arising out of this Agreement or the Program. Any claim or cause of action arising out of or related to this Agreement or the Program must be filed no later than one year after such claim
                has accrued. TSB, shall be entitled to an injunction, preliminary injunction, temporary restraining order, or other form of equitable relief restraining any breach or violation of this Agreement, and to exercise all other legal and equitable
                remedies afforded to it by law, and to recover from Client reasonable costs and attorneys’ fees incurred by TSB in exercising any such remedy. </p>


            <p> IN WITNESS WHEREOF, the parties, intending to be legally bound, have executed this Agreement by their authorized representatives, effective as of the latest date of signature below. </p>

            <ul class="details">
                <li>THE SCHOOL OF BIZ </li>
                <li><span class="dash" style="width: 100%;">__________________________________________________</span></li>
                <li>By <span class="dash">__________________________________________________</span></li>
                <li>By <span class="dash">__________________________________________________</span></li>
                <li>George Theodore Wolf II </li>
                <li>Name: </li>
                <li>President </li>
                <li>Title: </li>
                <li>Date: </li>
                <li> Date:</li>
            </ul>
        </div>
    </div>
</div>