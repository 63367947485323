<div class="wrapper" id="wrapper">
    <!--  header start -->
        <header class="header">
            <div class="container">
                <div class="headLogo"><a  class="logo"><img src="../../../assets/images/Growthdrivers.svg"></a></div>
            </div>
        </header>
        <!--  header start -->

        <!-- main section  -->
        <section class="bodyContainer">
            <div class="container">
                    <div class="successWrap">
                        <div class="successIcon"><img src="../../../assets/images/alert_icon.svg"></div>
                        <h2>Expried</h2>
                        <p>Sorry! The link has expired </p>
                        <!-- <button type="button" class="commonBtn saveBtn"> Go to Home </button> -->

                    </div>
                </div>
        </section>
        <!-- section end  -->
    </div>
