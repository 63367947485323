<div class="top-bar" ng-controller="Header">
    <div class="left logoSection logSec_with" [ngClass]="userData?.is_root_admin ? 'org-dropdown' : '' ">
        <div class="menu-button">
            <span class="stick1"></span>
            <span class="stick2"></span>
            <span class="stick3"></span>
        </div>
        <button type="button" data-toggle="tooltip" data-placement="top" [title]="show==true ? 'Collapse':'Expand'" class="sidebar-toggler sideToggle"  [ngClass]="show==true ? '':'hideMenu '" (click)="toggleNavbar($event)">
            <svg class="icon" viewBox="0 0 52 34">
                <path d="M49,4H19c-0.6,0-1-0.4-1-1s0.4-1,1-1h30c0.6,0,1,0.4,1,1S49.6,4,49,4z"></path>
                <path d="M49,16H19c-0.6,0-1-0.4-1-1s0.4-1,1-1h30c0.6,0,1,0.4,1,1S49.6,16,49,16z"></path>
                <path d="M49,28H19c-0.6,0-1-0.4-1-1s0.4-1,1-1h30c0.6,0,1,0.4,1,1S49.6,28,49,28z"></path>
                <path class="hidePath"
                    d="M8.1,22.8c-0.3,0-0.5-0.1-0.7-0.3L0.7,15l6.7-7.8c0.4-0.4,1-0.5,1.4-0.1c0.4,0.4,0.5,1,0.1,1.4L3.3,15l5.5,6.2   c0.4,0.4,0.3,1-0.1,1.4C8.6,22.7,8.4,22.8,8.1,22.8z">
                </path>
            </svg>
        </button>
        <div class="logo-img">
            <a (click)="goToDashbord()" style="cursor: pointer;">
                <img src="assets/images/Growthdrivers.svg" alt="" />
            </a>
        </div>
            
        <div class="company_select" *ngIf="userData?.is_root_admin">
            <p class="entry_name">Organization:</p>
            <div class="form">
                <div class="form-group m-0">
                    <div #d="ngbDropdown"  class="add_cpanoy" ngbDropdown #myDrop="ngbDropdown"
                        autoClose="outside" placement="bottom-left">
                        <a id="dropdownNewPlayList" class="form form-control comap_drop" ngbDropdownToggle style="margin-top: 0;"
                            placement="bottom">
                            {{companyTitle || 'Select Company'}}
                        </a>
                        <div  class="dropdown-menu p-0 drop_down"
                            ngbDropdownMenu aria-labelledby="dropdownNewPlayList" aria-labelledby="dropdownConfig">
                            <div class="searchBox">
                                <input minlength="2" type="text" placeholder="Search..." [(ngModel)]="searchGoalTxt"
                                    [ngModelOptions]="{standalone: true}" (keyup)="onKeypressEvent($event)">
                                <a (click)="removeGDData()" class="closeSearch"
                                    [ngClass]="!searchGoalTxt ? 'search':'' "></a>
                            </div>
                            <ul class="autoComplete">
                                <li *ngFor="let gd of allCompanyData; let i = index" (click)="closeDropdown()">
                                    <!-- <div class="checkBoxWrap">
                                        <input type="checkbox" id="{{i+11}}" (change)="selectGD($event,gd,i)" [checked]="checkGD(gd.id)">
                                        <label for="{{i+11}}" class="input-helper hideCheckBorder">{{gd.name}}</label>
                                    </div> -->
                                    <a (click)="selectGD(gd, i)" [ngClass]="gd?.name == companyTitle ? 'green':''">{{gd?.name}}</a>
                                </li>
                            </ul>
                            <div class="No_found" style="text-align: center; padding: 20px 0;" *ngIf="allCompanyData?.length==0">
                                {{!isSubjectRes ? "Please wait..." : "No results found"}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="left" *ngIf="showSearch">
        <div class="search_bar">
            <input type="text" placeholder="Search..." [(ngModel)]="searchText" (input)="searchItem($event.target.value)" />
            <input type="submit" class="search-icon" />
            <a class="closeBtn" (click)="refreshPage()" *ngIf="closeSearch"><i class="mdi  mdi-close"></i></a>
        </div>
    </div>
    <div class="right-nav right">
        <ul>
            <li style="border-right:none;">
                <div ngbDropdown class="d-inline-block enteryWrap" [autoClose]="true">
                    <a class="createBtn user-nm" placement="bottom-right" id="dropdownBasic1" ngbDropdownToggle ngbDropdownAnchor>
                        Create New Entry </a>
                    <div class="dropdownWrap p-0" ngbDropdownMenu aria-labelledby="dropdownBasic1">
                        <a (click)="createEntry(1)"><span class="entryIcon gdIcon"></span> Growthdriver</a>
                        <a (click)="createEntry(3)"><span class="entryIcon goalIcon"></span> Goal</a>
                        <a (click)="createEntry(4)"><span class="entryIcon projectIcon"></span> Project</a>

                    </div>
                </div>
            </li>
            <li>
                <div class="d-inline-block" placement="bottom-right"  ngbDropdown [autoClose]="true">
                    <div class="profile-pic" placement="bottom-right" id="dropdownManual" ngbDropdownToggle ngbDropdownAnchor>
                        <div class="user-pic">
                            <div class="avatarWrap">
                                <ul class="avtarIcon">
                                    <li class="active">
                                        <label class="avatars color_{{avatarColor}}">
                                            <img src="assets/images/avatar_w{{avatarImage}}.svg">
                                        </label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="user-nm"><span class="firstlCaptial">{{userData.full_name}}</span></div>
                    </div>
                    <div class="dropdown-menu my-dropdown" ngbDropdownMenu aria-labelledby="dropdownManual">
                        <div class="profileInfo">
                            <div style="padding-left:25px">
                                <h5>{{userData.full_name}}</h5>
                                <div class="userPoints">{{userData.points || 0}} points</div>
                            </div>
                            <a class="viewProfile text-left"  ngbDropdownItem (click)="gotoProfile()">View Profile</a>

                        </div>
                        <div [ngStyle]="{'cursor':'pointer'}" class="dropdown-menu-footer">
                            <a class="text-left" (click)="logout()"> Log Out </a>
                        </div>
                    </div>
                </div>
            </li>
            <li>
                <div class="navdrop-wrap navdrop notification-dropdown settings">
                    <a (click)="getNotification()" routerLink="/notifications" class="navdrop-box custom-tooltip nav-link-common">
                        <span class="mdi mdi-bell"></span>
                        <span class="notice-label" *ngIf="notification > 0 "></span>
                        <span class="heartbeat"></span>
                    </a>
                </div>
            </li>
            <li>
                <div class="navdrop-box custom-tooltip">
                    <a (click)="getNotification()" routerLink="/settings" class="link-menu">
                        <span class="mdi mdi-settings"></span>
                    </a>
                </div>
            </li>
        </ul>

    </div>
</div>
<!-- topbar end -->
<div class="sidebar">
    <div class="sidebar-inner">
        <div class="nav-outer scroll_custom">
            <ul class="ulscroll">
                <li>
                    <h5 class="light">
                        <span class="menu-title">Review</span>
                    </h5>
                </li>
                <li routerLinkActive="active">
                    <a (click)="getNotification()" routerLink="/growthdriver">
                        <span class="menu-title">Growthdrivers</span>
                    </a>
                </li>
                <li routerLinkActive="active">
                    <a (click)="getNotification()" routerLink="/goals">
                        <span class="menu-title">Goals</span>
                    </a>
                </li>
                <li routerLinkActive="active">
                    <a (click)="getNotification()" routerLink="/project">
                        <span class="menu-title">Projects</span>
                    </a>
                </li>
                <!-- <li routerLinkActive="active">
                    <a routerLink="/vault">
                        <span class="menu-title">Vault</span>
                    </a>
                </li> -->
                <li routerLinkActive="active">
                    <a (click)="getNotification()" routerLink="/growth-mantras">
                        <span class="menu-title">Growthmantras</span>
                    </a>
                </li>
                <li (click)="getNotification()" routerLinkActive="active">
                    <a routerLink="/observations">
                        <span class="menu-title">Observations</span>
                    </a>
                </li>
                <li>
                    <h5 class="light">
                        <span class="menu-title">Analytics</span>
                    </h5>
                </li>
                <li (click)="getNotification()" routerLinkActive="active">
                    <a routerLink="/dashboard">
                        <span class="menu-title">Dashboard</span>
                    </a>
                </li>
                <li (click)="getNotification()" routerLinkActive="active">
                    <a routerLink="/leaderboard">
                        <span class="menu-title">Leaderboard</span>
                    </a>
                </li>
                <li (click)="getNotification()" routerLinkActive="active">
                    <a routerLink="/badges">
                        <span class="menu-title">Badges</span>
                    </a>
                </li>
                <li routerLinkActive="active">
                    <a (click)="getNotification()" routerLink="/reporting">
                        <span class="menu-title">Reporting</span>
                    </a>
                </li>
                <!-- <li routerLinkActive="active">
                    <h5 class="light">
                        <span class="menu-title">Growthmantras</span>
                    </h5>
                </li> -->

                <li *ngIf="this.deanAccess || admin1Access">
                    <h5 class="light">
                        <span class="menu-title">Admin Console</span>
                    </h5>
                </li>
                <li routerLinkActive="active"  *ngIf="this.deanAccess || admin1Access">
                    <a (click)="getNotification()" routerLink="/student-management">
                        <span class="menu-title">Member Management</span>
                    </a>
                </li>
                <li routerLinkActive="active" *ngIf="this.deanAccess">
                    <a (click)="getNotification()" routerLink="/admin-management">
                        <span class="menu-title">Admin Management</span>
                    </a>
                </li>
                <li routerLinkActive="active" *ngIf="this.deanAccess || admin1Access">
                    <a (click)="getNotification()" routerLink="/team-management">
                        <span class="menu-title">Team Management</span>
                    </a>
                </li>
                <li routerLinkActive="active" *ngIf="this.deanAccess || admin1Access">
                    <a (click)="getNotification()" routerLink="/organization-management">
                        <span class="menu-title">Organization Management</span>
                    </a>
                </li>
                <li>
                    <h5 class="light">
                        <span class="menu-title">Help</span>
                    </h5>
                </li>
                <li routerLinkActive="active">
                    <a (click)="getNotification()" routerLink="/FAQ">
                        <span class="menu-title">FAQs</span>
                    </a>
                </li>
                <li routerLinkActive="active">
                    <a (click)="getNotification()" routerLink="/support">
                        <span class="menu-title">Support</span>
                    </a>
                </li>
            </ul>
            <div class="copyright">
                <a href="https://www.guidewise.io/" target="_blank" rel="noopener noreferrer">
                    <img class="_logo" src="../../../../assets/images/guidewise_primary mark_final.jpg">
                    </a>
                <div><span>Growthdrivers R3.0.0</span>
                    <p>&copy; 2021 - 2025
                        <!-- {{date | date: 'y'}} -->
                        Guidewise</p></div>
            </div>
        </div>
    </div>
</div>
<ng-template #roleChange>
    <div class="modal-header">
        <div class="tick_icon" style="text-align: center;">
            <img src="assets/images/successful.svg">
        </div>

    </div>
    <!-- Modal body -->
    <div class="modal-body modalpoppu" style="text-align: center;">
        <h4 class="modal-title" style="text-align: center; text-transform: capitalize;">Your Growthdrivers Access Has Changed</h4>
        <p>Your Growthdrivers user role has changed from
            <span *ngIf="previousProUser_type == 3" class="value">Member</span>
            <span *ngIf="previousProUser_type == 2 && previousProRole == 2" class="value">Admin</span>
            <span *ngIf="previousProUser_type == 2 && previousProRole == 1" class="value">Super Admin</span> to 
            
        <span *ngIf="profileData?.data?.user_type == 3" class="value">Member</span>
        <span *ngIf="profileData?.data?.user_type == 2 && profileData?.data?.role == 2" class="value">Admin</span>
        <span *ngIf="profileData?.data?.user_type == 2 && profileData?.data?.role == 1" class="value">Super Admin</span>. Please click on the button below to continue.
    </p>
    </div>
    <div class="modal-footer modalpoppu">
        <a  (click)="roleChanges()" class="status-completed innerCss">OKAY</a>
    </div>
</ng-template>