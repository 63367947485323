import { ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { HttpErrorResponse } from '@angular/common/http';
import { AdminService } from './../../services/admin.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { NgOtpInputComponent } from 'ng-otp-input';
import { Subscription } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { startWith } from 'rxjs/operators';
import { DataShareService } from 'src/app/services/data-share.service';
import { Location } from '@angular/common';
import * as CONSTANT from './../../services/constants';

@Component({
  selector: 'app-idle',
  templateUrl: './idle.component.html',
  styleUrls: ['./idle.component.scss']
})
export class IdleComponent implements OnInit {
  updatePin: FormGroup;
  idleState = 'Not started.';
  timedOut = false;
  oldPassWord: any;
  baseUrl: string = environment.backend.baseURL;
  showError = false;
  forgotPassWord: boolean = false;
  pwdForm: FormGroup = null;
  lastPing?: Date = null;
  modalRef: BsModalRef;
  pin: any;
  passwordType: string = 'password';
  passwordShown: boolean = false;
  pinError = false;
  newPin: any;
  confirmPin: any;
  notSameError = false;
  newPinError = false;
  pwdResetForm: FormGroup = null;
  warningMsg = '';
  confirmPinError = false;
  // ngOtpInput: NgOtpInputComponent;
  config = {
    allowNumbersOnly: true,
    length: 4,
    isPasswordInput: true,
    autofocus: true,
    placeholder: '',
    inputStyles: {
      'font-size': '20px',
    }
  };
  subscription: Subscription;
  showOtpComponent: boolean = true;
  showPinChangeComponent = true;
  count = 0;
  otpCount = 0;
  showModal: boolean = false;
  time: number = 30;
  showResetError: boolean = false;
  @ViewChild(NgOtpInputComponent, { static: false }) ngOtpInput: NgOtpInputComponent;
  @ViewChild('forgotPinPopup') forgotPinPopup: TemplateRef<any>;
  sessionPopUp: boolean = false;
  checkenablePin: boolean = false;
  constructor(private idle: Idle, private keepalive: Keepalive, private modalService: BsModalService, public formBuilder: FormBuilder, private api: AdminService, private cdr: ChangeDetectorRef, private router: Router, private dataShare: DataShareService, private location: Location) {
    idle.setIdle(1);
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    idle.setTimeout(this.time);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.'
      this.reset();
    });

    idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      const attempts = localStorage.getItem("pinAttempts");
      const res = Number(attempts);
      if ((!res || res < 5)) {
        this.showOtpComponent = true;
        this.sessionPopUp = true;
      } else if (res > 4) {
        this.forgotPin(this.forgotPinPopup);
        this.showResetError = true;
        this.sessionPopUp = false;
      }
    });

    idle.onIdleStart.subscribe(() => {
      this.idleState = 'You\'ve gone idle!'
    });
    // sets the ping interval to 15 seconds
    keepalive.interval(1800);
    keepalive.onPing.subscribe(() => this.lastPing = new Date());
    this.reset();
    this.pwdResetForm = this.formBuilder.group({
      'email': ['', [Validators.required, Validators.email, Validators.pattern(CONSTANT.EMAIL_REGEX)]],
    })
  }

  ngOnInit(): void {
    this.reset();
    this.pwdForm = new FormGroup({
      password: new FormControl("", Validators.required),
    });
    this.router.events.forEach((event: any) => {
      if (event instanceof NavigationEnd) {
        if (event.url.includes('forgot-password') || event.url.includes('login') || event.url.includes('play-audio') || event.url.includes('generate-password') || event.url.includes('faq?web=1') || event.url.includes('contactus') || event.url.includes('terms-conditions') || event.url.includes('privacy-policy') || !event.url || event.url == '/') {
          this.idle.stop();
          this.sessionPopUp = false;
        } else {
          let enablePin = JSON.parse(localStorage.getItem('enablePin'));
          if (enablePin) {
            this.idle.watch();
          }
        }
      }
    });
    this.subscription = this.api.idleTimer.subscribe(res => {
      if (res) {
        let enablePin = JSON.parse(localStorage.getItem('enablePin'));
        if (enablePin) {
          this.idle.watch();
        }
      } else {
        this.sessionPopUp = false;
        this.idle.stop();
      }
    })
    this.subscription = this.api.idleTimershow.subscribe(res => {
      if(res) {
        this.sessionPopUp = true;
        this.checkenablePin = true;
      } else {
        this.sessionPopUp = false;
        this.checkenablePin = false;
        // this.idle.stop();
      }
    })
  }
  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }

  closePopup() {
    if (this.modalRef) {
      this.modalRef.hide();
    }
  }
  otpSubmit() {
    if (!this.pin || this.pin.length < 4) {
      this.pinError = true;
      return false
    }
    let data = {
      'login_pin': this.pin,
      'access_token': localStorage.getItem('access_token'),
    }
    this.api.getData(`${this.baseUrl}/api/v2/pins/verify_pin`, data)
      .subscribe(async (res) => {
        this.otpCount = 0;
        this.sessionPopUp = false;
        this.closePopup();
        this.reset();
        if(this.checkenablePin) {
          this.pinenable(!this.checkenablePin)
        }
      },
        (error: HttpErrorResponse) => {
          // this.api.errorToast('', error.error.message);
          this.otpCount = 0;
          localStorage.setItem('pinAttempts', JSON.stringify(error.error.data.pin_attempts));
          if (error.error.data.pin_attempts < 5) {
            if (error.error.data.pin_attempts == 1) {
              this.api.errorToast('', 'You have entered the wrong PIN. You have four attempts left');
            } else if (error.error.data.pin_attempts == 2) {
              this.api.errorToast('', 'You have entered the wrong PIN. You have three attempts left');
            } else if (error.error.data.pin_attempts == 3) {
              this.api.errorToast('', 'You have entered the wrong PIN. You have two attempts left');
            } else if (error.error.data.pin_attempts == 4) {
              this.api.errorToast('', 'You have entered the wrong PIN. You have one attempt left');
            }
          } else {
            this.api.errorToast('', 'You have no more PIN attempts left. Please reset the password');
            this.forgotPin(this.forgotPinPopup);
            this.showResetError = true;
          }
          this.resetOtp();
        },
        () => {

        })
  }
  pinChange(e) {
    this.pin = e;
    this.pinError = false;
    if (this.pin.length == 4 && this.otpCount == 0) {
      this.otpCount = 1;
      this.otpSubmit();
    }
  }
  goToForgot(template) {
    this.pwdForm.patchValue({ password: '' });
    this.modelCommonFunction(template);
    this.newPin = '';
    this.confirmPin = '';
  }
  modelCommonFunction(template: TemplateRef<any>) {
    this.closePopup();
    this.modalRef = this.modalService.show(template, {
      animated: true,
      backdrop: 'static',
      class: 'modal-dialog-centered w-3 blured'
    });

  }
  checkPassword(form, template) {
    if (form.valid) {
      let data = {
        'password': form.value.password,
        'access_token': localStorage.getItem('access_token'),
      }
      this.api.getData(`${this.baseUrl}/api/v2/passwords/verify`, data)
        .subscribe(async (res) => {
          this.oldPassWord = form.value.password;
          form.patchValue({ password: '' });
          this.modelCommonFunction(template);
          // localStorage.removeItem('pinAttempts');
          this.showError = false;
          this.forgotPassWord = true;
        },
          (error: HttpErrorResponse) => {
            this.api.errorToast('', error.error.message);
            console.log(error);
          },
          () => {

          })

    } else {
      this.showError = true;
    }
  }
  public togglePassword() {
    if (this.passwordShown) {
      this.passwordShown = false;
      this.passwordType = 'password';
    } else {
      this.passwordShown = true;
      this.passwordType = 'text';
    }
  }
  forgotPin(template) {
    this.sessionPopUp = false;
    this.idle.stop();
    this.modelCommonFunction(template);
  }
  newPinChange(e) {
    this.newPin = e;
    this.newPinError = false;
    this.notSameError = false;
  }
  confirmPinChange(e) {
    this.confirmPin = e;
    this.confirmPinError = false;
    this.notSameError = false;
  }
  changePinSubmit() {
    if (!this.newPin || this.newPin.length < 4) {
      this.newPinError = true;
      return false
    }
    if (!this.confirmPin || this.confirmPin.length < 4) {
      this.confirmPinError = true;
      return false
    }
    if (this.confirmPin != this.newPin) {
      this.notSameError = true;
      this.newPin = '';
      this.confirmPin = '';
      this.showPinChangeComponent = false;
      setTimeout(() => {
        this.showPinChangeComponent = true;
      }, 0);
      this.cdr.detectChanges();
      return false
    }
    let data = {
      'login_pin': this.confirmPin,
      'password': this.oldPassWord,
      'access_token': localStorage.getItem('access_token'),
    }
    this.api.postData(`${this.baseUrl}/api/v2/pins/forgot_pin`, data)
      .subscribe(async (res) => {
        this.closePopup();
        this.sessionPopUp = false;
        this.idle.watch();
        if(this.checkenablePin) {
          this.pinenable(!this.checkenablePin)
        }
        // this.api.disablePin(this.checkenablePin);
        localStorage.removeItem('pinAttempts');
        this.showError = false;
        this.api.toast1(`<i class="mdi bigSize  mdi-check-circle-outline"></i> <span class="font_size">Successfully reset</span>`, '');
        this.reset();
        this.showResetError = false;
        this.forgotPassWord = false;
        this.newPin = '';
        this.confirmPin = '';
      },
        (error: HttpErrorResponse) => {
          console.log(error);
          this.api.errorToast('', error.error.message);
        },
        () => {

        })

  }
  sendEmail(form, template) {
    if (form.valid) {
      let data = {
        email: form.value.email,
        user_type: '2',
      }
      this.api.postData(`${this.baseUrl}/api/v1/user/forgot`, data)
        .subscribe(async (res) => {
          this.modelCommonFunction(template);
          this.showError = false;
        },
          (error: HttpErrorResponse) => {
            console.log(error);
          },
          () => {

          })
    } else {
      this.showError = true;
    }
  }
  forgotPwd(template) {
    this.modelCommonFunction(template);
    this.pwdResetForm.patchValue({ email: '' });
  }

  resetOtp() {
    this.showOtpComponent = false;
    setTimeout(() => {
      this.showOtpComponent = true;
    }, 0);
    this.cdr.detectChanges();
  }

  pinenable(event) {
    const profileObj = {
      access_token: localStorage.getItem('access_token'),
      is_pin_enable: event ? 1 : 0
    };
    this.api.postData(`${this.baseUrl}/api/v1/user/profile`, profileObj)
    .subscribe(async (res) => {
      localStorage.setItem('enablePin', JSON.stringify(event));
      this.api.startTimer(event);
      if(event) {
        this.api.toast1('<i class="mdi bigSize  mdi-check-circle-outline"></i> <span class="font_size">PIN enabled</span>', '');
      } else {
        this.api.toast1('<i class="mdi bigSize  mdi-check-circle-outline"></i> <span class="font_size">PIN disabled</span>', '');
      }
    },
      (error: HttpErrorResponse) => {
        console.log(error);
      })
  }

}
