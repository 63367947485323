import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { apiEndPoint } from '../../shared/apiEndPoints';
import { AdminService } from '../../services/admin.service';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  baseUrl: string = environment.backend.baseURL;
  profileCreated = false;
  rememberMe = '';
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  }

  constructor(private readonly http: HttpClient, private api: AdminService, private readonly router: Router) { }

  isAuthenticated(): boolean {
    const token = localStorage.getItem('access_token');
    // Check whether the token is expired and return
    // true or false
    let status = false;
    if (token && this.profileCreated === true) {
      status = true;
    }
    return status;
  }

  login(token: string): void {
    localStorage.setItem('access_token', token);
  }

  logout(all) {
    let userInfo = JSON.parse(localStorage.getItem('gdUserInfo'));
    return this.api.postData(apiEndPoint.LOG_OUT, {
      access_token: localStorage.getItem('access_token'),
      "user_id":userInfo?.id,
      "logout_all_devices":all
    }).subscribe((res) => {
      localStorage.removeItem('access_token');
      localStorage.removeItem('gdUserInfo');
      sessionStorage.removeItem('lastRoute');
      sessionStorage.removeItem('tempLogin');
      localStorage.clear();
      this.router.navigate(['/login']);
    },
      (error: HttpErrorResponse) => {
        console.log(error);
        localStorage.removeItem('access_token');
        localStorage.removeItem('gdUserInfo');
        sessionStorage.removeItem('lastRoute');
        sessionStorage.removeItem('tempLogin');
        localStorage.clear();
        this.router.navigate(['/login']);
      }
    );
  }

  setProfileStatus(val: boolean) {
    this.profileCreated = val;
  }


  isProfileCreated() {
    if (localStorage.getItem('access_token') !== null) {
      return true;
    }
    return false;
  }
}
