import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Component, OnInit, TemplateRef,Inject } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder, AbstractControl  } from '@angular/forms';
import { AdminService } from 'src/app/services/admin.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import * as CONSTANT from './../../../services/constants';
@Component({
  selector: 'app-share-link',
  templateUrl: './share-link.component.html',
  styleUrls: ['./share-link.component.scss']
})
export class ShareLInkComponent implements OnInit {
  baseUrl: string = environment.backend.baseURL;
  passwordForm: FormGroup = null;
  passCodeForm: FormGroup = null;
  passwordType: string = 'password';
  observation_form_token:any
  passwordShown: boolean = false;
  passwordWarningMsg: string;
  showPasswordSection = true;
  showUserDetailFrom = false;
  showPasscodeSection = false;
  clearButtonForOptional = false;
  showError = false;
  DataShared:any
  errorCode:any
  errorPassword:any
  errorPasscode:any
  otp_verify_token:any
  userDetailFrom: FormGroup ;
  
formattedDate;
  constructor(@Inject(DOCUMENT) private document: Document,
    private admin: AdminService,private router: Router,
    private route: ActivatedRoute) { 
     
      this.passwordForm = new FormGroup({
        password: new FormControl('', [Validators.required,Validators.minLength(6), Validators.maxLength(40)]),
      })
      this.passCodeForm = new FormGroup({
        password: new FormControl('', [Validators.required,Validators.minLength(6), Validators.maxLength(40)]),
      })
      this.userDetailFrom = new FormGroup({
        first_name: new FormControl('',),
        last_name: new FormControl('',),
        email: new FormControl('',[Validators.pattern(CONSTANT.EMAIL_REGEX)]),
      })
    }

  ngOnInit(): void {
 const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.formattedDate =userTimezone;
    this.getDeatil()
    
  }
  getDeatil() {
    let params = new HttpParams();
    params = params.append('access_token', localStorage.getItem('access_token'));
    params = params.append('time_zone', this.formattedDate);
    let token= this.route.snapshot.paramMap.get("id")
    this.admin.getData(`${this.baseUrl}/api/v3/observation_links/` +token , params)
      .subscribe(async (res) => {
        this.errorCode=res.status   
        this.DataShared=res.data
        if(this.DataShared.is_password_enable){
          this.showPasswordSection=true
          this.showUserDetailFrom=false
        }else if(!this.DataShared.is_respondents_anonymous){
          this.showPasswordSection=false
          this.showUserDetailFrom=true
        }else{
          this.showPasswordSection=false
          this.showUserDetailFrom=true
        }
        if(!this.DataShared.is_respondents_anonymous){
          this.userDetailFrom.get('first_name').setValidators(Validators.required)
          this.userDetailFrom.get('last_name').setValidators(Validators.required)
          this.userDetailFrom.get('email').setValidators([Validators.required,Validators.pattern(CONSTANT.EMAIL_REGEX)])
        }
      
      },err => {
        this.errorCode =err.status;
      }
      )
  }
  onEnterKey(event: KeyboardEvent) {
    if (this.passwordForm.valid) {
      this.VerifyPassword();
    }
    event.preventDefault(); // Prevent the default form submission if needed
  }
  VerifyPassword() {
    if (this.passwordForm.invalid){
      this.showError = true
    }
    let data={
      access_token:localStorage.getItem('access_token'),
      password: this.passwordForm.value.password
    }
    let token= this.route.snapshot.paramMap.get("id")
    this.admin.postData(`${this.baseUrl}/api/v2/observation_respondents/` + token +'/verify_password' , data)
      .subscribe(async (res) => {
        this.errorPassword=res.status
        if(this.errorPassword == 200){
           this.admin.toast1('<i class="mdi bigSize  mdi-check-circle-outline"></i><span class="font_size">Password successfully entered</span>', ''); 
        }
        setTimeout(() => {
          this.showPasswordSection =false
          this.showUserDetailFrom =true
          this.showPasscodeSection =false;
          this.showError = false
        }, 3000);
    
      },err => {
        this.errorPassword=err.error.status; 
        setTimeout(() => {
        this.errorPassword = 0
        this.showError = false
        }, 3000);
    })
  }
  VerifyDetails(type) { 
    if(this.userDetailFrom.valid){
    let data={
      id:this.route.snapshot.paramMap.get("id"),
      first_name:this.userDetailFrom.value.first_name,
      password: this.passwordForm.value.password,
      last_name: this.userDetailFrom.value.last_name,
      email: this.userDetailFrom.value.email
    }
    this.admin.postData(`${this.baseUrl}/api/v2/observation_respondents` , data)
      .subscribe(async (res,) => {
        this.otp_verify_token=res.data.otp_verify_token
        this.observation_form_token=res.data.observation_form_token
        if(res.status!=222 && res.status!=111){
        this.showPasswordSection =false
        this.showUserDetailFrom =false
        if(this.userDetailFrom.value.email){
        this.showPasscodeSection =true
        if(type=='resend'){
          this.admin.toast1('<i class="mdi bigSize  mdi-check-circle-outline"></i><span class="font_size">One-time passcode successfully resent</span>', '');}

        }else{
          this.router.navigate(['/user-observations/' + this.observation_form_token])
          
        }
        }else{
          this.errorCode=res.status
          setTimeout(() => {
            this.errorCode=200
          }, 5000);
        }
      })
    }
  }
  
  // passowrd
  public togglePassword() {
    if (this.passwordShown) {
      this.passwordShown = false;
      this.passwordType = 'password';
    } else {
      this.passwordShown = true;
      this.passwordType = 'text';

    }
  }
  onSubmit(){
  
      if (this.passCodeForm.valid){
        let data={
          otp_verify_token:this.otp_verify_token,
          otp: this.passCodeForm.value.password
        }
        this.admin.postData(`${this.baseUrl}/api/v2/observation_respondents/verify_otp` , data)
          .subscribe(async (res,) => {
            this.errorPasscode=res.status
            if(this.errorPasscode == 200){
              this.admin.toast1('<i class="mdi bigSize  mdi-check-circle-outline"></i><span class="font_size">Passcode successfully entered</span>', '');
            }
            setTimeout(() => {
            this.router.navigate(['/user-observations/' + this.observation_form_token])
            }, 3000);
          },err => {this.errorPasscode=err.error.status;console.log(this.errorPasscode);setTimeout(() => {
            this.errorPasscode =0
          }, 3000);})
      }
      
  }
  backStep(event,number){
      this.showPasswordSection =false
      this.showUserDetailFrom =true
      this.showPasscodeSection =false
  }
  clear(num){
    if(num==1){
      this.passwordForm.reset()
    }
    if(num==2){
      this.userDetailFrom.reset()
    }
    if(num==3){
      this.passCodeForm.reset()
    }
  }
  checkOptionFiledRequired(){
    if (this.DataShared?.is_respondents_anonymous == true){
      return !this.userDetailFrom.value.first_name || !this.userDetailFrom.value.last_name || !this.userDetailFrom.value.email;
    } else return false;
  }
}
