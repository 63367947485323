<div class="wrapper login-section">
    <!-- ==== loginHeader ===== -->
    <div class="loginHeader">
        <div class="container">
            <a *ngIf="webType!=1"  routerLink="/" class="logo">
                <img src="assets/images/Growthdrivers.svg" />
            </a>
            <div *ngIf="webType==1">
                <app-loginheader></app-loginheader>
            </div>
        </div>
    </div>
    <!-- loginHeader end  -->
    <div class="terms">
        <app-privacy-policy-view [back]="true"></app-privacy-policy-view>
    </div>
    <div class="footerWrap">
        <div class="copyright">
            <a class="_logo_a mt-2" href="https://www.guidewise.io/" target="_blank" rel="noopener noreferrer">
                <img class="_logo" src="../../../assets/images/guidewise_primary mark_final.jpg">
            </a>
            <span>Growthdrivers R3.0.0</span>
            <p>&copy; 2021 - 2025 Guidewise</p>
        </div>
    </div>
</div>