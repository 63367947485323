<div class="wrapper login-section">
    <!-- ==== loginHeader ===== -->
    <div class="loginHeader">
        <div class="container">
            <app-loginheader></app-loginheader>
        </div>
    </div>
    <!-- loginHeader end  -->
    <div class="main_container">
        <div class="container">
            <div class="login-container">
                <div class="row">
                    <div class="col-12 col-lg-5 rightSection">
                        <div class="innerBox">
                            <!-- <a  class="logo">
                                <img src="assets/images/Growthdrivers.svg" alt="logo">
                            </a> -->
                            <div class="itemBox">
                                <h2>Welcome to the <span class="themeColor"> Growthdrivers Web App</span></h2>
                            </div>

                            <div class="downloadBox">
                                <h4>Download our Mobile App</h4>
                                <p>To download the Growthdrivers mobile app, click on the buttons below.</p>
                                <div class="downloadBtn_wrap">
                                    <a target="_blank" href="https://apps.apple.com/sn/app/growthdrivers/id1564691592"><img src="assets/images/appstore.svg"> </a>
                                    <a target="_blank" href="https://play.google.com/store/apps/details?id=com.app.growthdriver"><img src="assets/images/google_play.svg"> </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-7 leftSection">
                        <div class="loginWrap">
                            <p>Please enter your user credentials below.</p>
                            <div class="form-box">
                                <form [formGroup]="loginForm" (ngSubmit)="handleForm()">
                                    <div class="form">

                                        <div class="form-group">
                                            <input type="text" class="form-control" placeholder="Username or Work Email Address*" formControlName="email" [(ngModel)]="loginModel.email" tabindex=1 />
                                            <!-- <span *ngIf="emailWarningMsg" class="warnings pl-1">{{emailWarningMsg}}</span> -->
                                            <span *ngIf="userNotFound" class="warnings pl-1">This email or username is
                                                not registered</span>
                                            <span *ngIf="loginForm.controls.email.errors?.pattern && warningMsg" class="warnings">Please enter valid email address</span>

                                        </div>

                                        <div class="form-group">
                                            <input [type]="passwordType" class="form-control" placeholder="Password*" formControlName="password" [(ngModel)]="loginModel.password" tabindex=2 />
                                            <span *ngIf="passwordWarningMsg" class="warnings pl-1">{{passwordWarningMsg}}</span>
                                            <span *ngIf="commonMsg" class="warnings pl-1">{{commonMsg}}</span>
                                            <a  class="showPass" (click)="togglePassword()">
                                                <img [src]="passwordShown == false? 'assets/images/eye-off-2.svg' :'assets/images/ic_eye_show_red.svg' " tabindex=3 /></a>
                                        </div>

                                        <div class="form-group">
                                            <div class="row align-items-center">
                                                <div class="col-7">
                                                    <label class="checkbox leftlabel"><input type="checkbox"
                                                            [checked]="isRemember" id="rememberCB"
                                                            (change)="changeRememberMe($event)" value="true"
                                                            for="rememberCB" tabindex="4" /><i
                                                            class="input-helper"></i>Keep me logged in</label>
                                                </div>
                                                <div class="col-5">
                                                    <a routerLink="/forgot-password" class="forgotPwd" tabindex="5">Forgot
                                                        password?</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group captcha">
                                            <re-captcha id="recaptcha" name="recaptcha" [(ngModel)]="token" [ngModelOptions]="{standalone: true}"></re-captcha>
                                            <div class="warnings" *ngIf="captchaError">
                                                *Please check the recaptcha
                                            </div>
                                        </div>
                                        <div class="submitWrap">
                                            <button class="btn btn-primary" type="submit" [disabled]="!loginModel.email || !loginModel.password || !token" [class.disable]="!loginModel.email || !loginModel.password || !token">  LOG IN </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="footerWrap">
        <div class="copyright">
            <a class="_logo_a mt-2" href="https://www.guidewise.io/" target="_blank" rel="noopener noreferrer">
                <img class="_logo" src="../../../assets/images/guidewise_primary mark_final.jpg">
            </a>
            <span>Growthdrivers R3.0.0</span>
            <p>&copy; 2021 - 2025 Guidewise</p>
        </div>
    </div>

</div>

<ng-template #createPinModel>
    <div class="modal-header">
        <h5 class="modal-title text-center">Create PIN</h5>
    </div>
    <div class="popupBody">
        <p class="text-center">Please complete fields below to create PIN </p>
        <div class="otpWrap">
            <label>PIN</label>
            <div class="otpBox">
                <ng-otp-input *ngIf="ShowPin" (onInputChange)="newPinChange($event)" [config]="config">
                </ng-otp-input>
                <div class="error" *ngIf="newPinError">
                    *Please enter your PIN.</div>
            </div>
            <label>Confirm PIN</label>
            <div class="otpBox">
                <ng-otp-input *ngIf="ShowPin" (onInputChange)="confirmPinChange($event)" [config]="config1"></ng-otp-input>
                <div class="error" *ngIf="confirmPinError">*Please enter your confirm PIN.</div>
                <div class="error" *ngIf="notSameError">*PINs do not match. Please try again.</div>
            </div>
        </div>
        <div class="submit-btn">
            <button class="common-btn fill create_btn" [class.disable]="newPin?.length < 4 || confirmPin?.length < 4" [disabled]="newPin?.length < 4 || confirmPin?.length < 4" (click)="otpSubmit()">CREATE</button>
        </div>
    </div>
</ng-template>