<div class="row align-items-center">
    <div class="col-6">
        <a  routerLink="/" class="logo">
            <img src="assets/images/Growthdrivers.svg" />
        </a>
    </div>
    <div class="col-6">
        <ul class="navbar">
            <li><a [class.active]="activeClass==1"  [routerLink]="['/terms-conditions']" [queryParams]="{ 'web': '1'}" target="_blank">Terms of Use</a></li>
            <li><a [class.active]="activeClass==2"  [routerLink]="['/privacy-policy']" [queryParams]="{ 'web': '1'}" target="_blank">Privacy Policy</a></li>
            <li><a [class.active]="activeClass==3"  [routerLink]="['/faq']" [queryParams]="{ 'web': '1'}" target="_blank">FAQs</a></li>
            <li><a [class.active]="activeClass==4"  routerLink="/contactus" target="_blank">Contact Us</a></li>
            <li><a [class.active]="activeClass==5"  routerLink="/feedback" target="_blank">Feedback</a></li>
        </ul>
    </div>
</div>