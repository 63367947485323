import { Injectable, EventEmitter } from '@angular/core';
@Injectable({
    providedIn: 'root'
})

export class HeaderSearch {
    public ShowSearch: boolean = false;
    public IsInitiated: boolean = false;
    public SearchMethod: any;
    receivedFilter: EventEmitter<boolean>;
    constructor() {
        this.receivedFilter = new EventEmitter<boolean>();
    }
    // 2
    raiseEvent(showSearch: boolean, methodName: any): void {
        this.ShowSearch = showSearch;
        this.SearchMethod = methodName;
        this.receivedFilter.emit(showSearch);
    }

    unSubscribe() {
        this.ShowSearch = false;
        this.SearchMethod = null;
        this.receivedFilter.emit(false);
    }
}