import { Component, OnInit } from '@angular/core';
import { FormGroup, AbstractControl, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { AdminService } from '../../services/admin.service';
import { apiEndPoint } from '../../shared/apiEndPoints';


@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  public form: FormGroup;
  password: string = '';
  confirmPassword: string = '';
  token: string = '';
  showError = false;
  loading: boolean = false;
  passwordShown: boolean = false;
  ConfirmPasswordShown: boolean = false;
  passwordType: string = 'password';
  confirmPasswordType: string = 'password';

  constructor(private formBuilder: FormBuilder, private router: Router, private activeRoute: ActivatedRoute, private api: AdminService) {
    this.form = this.formBuilder.group({
      password: ['', Validators.compose([Validators.required, Validators.minLength(8), Validators.maxLength(16),  Validators.pattern(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*.#?&])[A-Za-z\dA-Z\d@$!%*.#?&]{8,16}$/)])],
      confirmPassword: ['', Validators.compose([Validators.required])]
    },
      {
        validator: this.MustMatch('password', 'confirmPassword')
      });
    this.activeRoute.params.subscribe(res => {
      this.token = res.token;
    });
  }

  ngOnInit() {
  }
  resetPassword() {
    if (this.form.valid) {
      this.showError = false;
      this.loading = true;
      const data = {
        'password': this.password,
        'repassword': this.confirmPassword,
        'access_token': this.token
      };
      this.api.postData(apiEndPoint.RESET_PASSWORD, data)
        .subscribe(res => {
          this.loading = false;
          if((res.status) && (res.status == '200')) {
            this.api.toast1('<i class="mdi bigSize  mdi-check-circle-outline"></i><span class="font_size">Successfully updated</span>', '');
            this.router.navigate(['/login']);
          } 
          else if(res.message) {
            this.api.errorAlert(res.message, false);
          } else {
            this.api.errorAlert('Something went wrong!', false);
          }
        },
          err => {
            console.log(err);
            this.loading = false;
          });
    } else {
      this.showError = true;
    }
  }

  MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }
  public togglePassword(type) {
    if(type == 'new') {
      if (this.passwordShown) {
        this.passwordShown = false;
        this.passwordType = 'password';
      } else {
        this.passwordShown = true;
        this.passwordType = 'text';
  
      }
    } else {
      if (this.ConfirmPasswordShown) {
        this.ConfirmPasswordShown = false;
        this.confirmPasswordType = 'password';
      } else {
        this.ConfirmPasswordShown = true;
        this.confirmPasswordType = 'text';
  
      }
    }
    
  }
}
