import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AdminService } from './../../services/admin.service';
import { NgForm } from '@angular/forms';
import { apiEndPoint } from './../../shared/apiEndPoints';
import * as CONSTANT from './../../services/constants';
import { NoWhitespaceValidator } from './../../services/NoWhiteSpace.validator';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.scss']
})
export class ContactusComponent implements OnInit {
  contactUs: FormGroup;
  showError = false;
  captchaError = false;
  showmsg = false;
  token: string | undefined;
  msg= '';
  constructor(public formBuilder: FormBuilder, private api: AdminService, private readonly router: Router,
    private location: Location,
    ) {
    this.contactUs = this.formBuilder.group({
      'firstName': ['', Validators.compose([Validators.required, Validators.minLength(3), NoWhitespaceValidator()])],
      'lastName': ['', Validators.compose([Validators.required, Validators.minLength(3), NoWhitespaceValidator()])],
      'email': ['', Validators.compose([Validators.required, Validators.pattern(CONSTANT.EMAIL_REGEX), NoWhitespaceValidator()])],
      'message': ['', Validators.compose([Validators.required, NoWhitespaceValidator()])],
    });
    this.token = undefined;
  }

  ngOnInit(): void {
  }
  onSubmit(form) {
    if (form.valid) {
      if (this.token) {
        this.captchaError = false;
        const data = {
          'type': "guest_support",
          'first_name': form.controls.firstName.value.trim(),
          'last_name': form.controls.lastName.value.trim(),
          'work_email': form.controls.email.value,
          'message': form.controls.message.value.trim(),
        }
        console.log(data, "data===")
        this.api.postData(apiEndPoint.CONTACT, data).subscribe(async (res) => {
          this.showmsg = true;
          this.msg = res?.message
        }, err => {
          console.log(err);
        });
      } else {
        this.captchaError = true;
        setTimeout(() => {
          this.captchaError = false;
        }, 2000);
      }

    } else {
      this.showError = true;
    }
  }

  trigger(event, type) {
    const inputValue = event.target.value;
    const result = inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
    if (type == 'first') {
      this.contactUs.controls.firstName.setValue(result);
    } else {
      this.contactUs.controls.lastName.setValue(result);
    }
  }

  checkValidForm() {
    if(this.contactUs.controls['message'].hasError('required') || this.contactUs.controls['email'].hasError('required') || this.contactUs.controls['lastName'].hasError('required') || this.contactUs.controls['firstName'].hasError('required')) {
      return true;
    } else {
      return false;
    }
  }

  goBack() {
    this.location.back();
  }
}
