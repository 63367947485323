import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CreateDataService {

  baseUrl: string = environment.backend.baseURL;

  constructor(private readonly http: HttpClient) { }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  }

  getCategoryData() {
    const httpOptions = {
      params: new HttpParams()
        .set('access_token', localStorage.getItem('access_token')),
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    }
    return this.http.get<any>(`${this.baseUrl}/api/v1/category`, httpOptions);
  }

  getEmotionData() {
    return this.http.post<any>(`${this.baseUrl}/api/v1/emotions/web`, {
      access_token: localStorage.getItem('access_token')
    }, this.httpOptions)
  }

  saveProgress(reqData: {}) {
    return this.http.post<any>(`${this.baseUrl}/api/v1/vaults`, reqData, this.httpOptions);
  }
  updateProgress(id, reqData) {
    console.log(`${this.baseUrl}/api/v1/vaults/edit`)
    console.log(id)
    return this.http.post<any>(`${this.baseUrl}/api/v1/vaults/edit`, reqData, this.httpOptions);
  }
  markAsComplete() {

  }




}
