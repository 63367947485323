<div class="wrapper login-section">
    <!-- ==== loginHeader ===== -->
    <div class="loginHeader">
        <div class="container">
            <app-loginheader></app-loginheader>
        </div>
    </div>
    <!-- loginHeader end  -->
    <ng-container *ngIf="!isForgotMailSend">
        <div class="main_container">
            <div class="container">
                <div class="login-container forgot-container">
                    <div class="forgotWrap">
                        <div class="d-flex">
                            <a routerLink="/login"> <img src="assets/images/arrow-back.svg" /></a> 
                            <h1 class="text-center w-100">
                                Forgot your password?
                            </h1>
                        </div>
                        <p>Don't worry. Updating your password is easy. Simply enter your work email address below.</p>
                        <div class="form-box">
                            <form class="form" [formGroup]="pwdResetForm" (ngSubmit)="sendEmail()">
                                <div class="form-group">
                                    <input type="email" class="form-control" placeholder="Work Email Address*" formControlName="email" />
                                    <span *ngIf="pwdResetForm.controls.email.errors?.pattern && warningMsg" class="warnings">Please enter valid email address</span>
                                </div>
                                <div class="submitWrap">
                                    <button type="submit" [disabled]="!pwdResetForm.controls.email?.value" [class.disable]="!pwdResetForm.controls.email?.value" class="btn btn-primary"> SEND </button>
                                </div>

                                <div class="backbtn">
                                    <a routerLink="/login" class="backTologin"> Go Back to Login</a>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </ng-container>

    <ng-container *ngIf="isForgotMailSend">
        <div class="main_container">
            <div class="container">
                <div class="login-container forgot-container">
                    <div class="forgotWrap">
                        <h1 class="text-center">
                            <!-- <a routerLink="/login"><img src="assets/images/arrow-back.svg" /></a>  -->
                            Update Link Sent
                        </h1>

                        <p>We have sent a password update email to <strong>{{pwdResetForm.value.email}}</strong>. Please click the password update link to update your password.</p>
                        <p>
                            <strong> Haven’t received the email yet? </strong>
                            <p>Please check your spam folder or resend another password update email.</p>
                            <div class="submitWrap">
                                <a (click)="sendEmail()" class="btn btn-primary">RESEND </a>
                            </div>
                            <div class="backbtn">
                                <a routerLink="/login" class="backTologin">Go Back to Login</a>
                            </div>

                    </div>
                </div>
            </div>
        </div>
    </ng-container>
    <div class="footerWrap">
        <div class="copyright">
            <a class="_logo_a mt-2" href="https://www.guidewise.io/" target="_blank" rel="noopener noreferrer">
                <img class="_logo" src="../../../assets/images/guidewise_primary mark_final.jpg">
            </a>
            <span>Growthdrivers R3.0.0</span>
            <p>&copy; 2021 - 2025 Guidewise</p>
        </div>
    </div>
</div>