import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.scss']
})
export class TermsOfUseComponent implements OnInit {
  showBack: boolean = true;
  @Input() back;
  constructor(private readonly router: Router,
    private location: Location,
    ) { }

  ngOnInit(): void {
    this.showBack = !this.back;
  }
  goBack() {
    this.location.back();
  }

}
