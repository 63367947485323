import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataShareService {
  public isLoginChanged: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isProfileChanged: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);  
  public isPermissionChanged: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isNotification: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isOrganizationChange: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
}
