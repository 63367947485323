<div class="wrapper login-section">
    <!-- ==== loginHeader ===== -->
    <div class="loginHeader">
        <div class="container">
            <app-loginheader></app-loginheader>
            <!-- <div class="row">
                <div class="col-6">
                    <a  routerLink="/" class="logo">
                        <img src="assets/images/Growthdrivers.svg" />
                    </a>
                </div>
                <div class="col-6">
                    <ul class="navbar" style="padding-top: 3px;">
                        <li><a target="_blank" [routerLink]="['/faq']" [queryParams]="{ 'web': '1'}"> FAQs </a></li>
                    </ul>
                </div>
            </div> -->
        </div>
    </div>

    <!-- loginHeader end  -->
    <div class="main_container">
        <div class="container">
            <div class="profileWrap shapes">
                <!-- <a  class="logo">
                    <img src="assets/images/Growthdrivers.svg" alt="logo" />
                </a> -->

                <h5 class="modal-title">Create Profile</h5>

                <div class="form-box">
                    <form [formGroup]="createProfileForm" (ngSubmit)="submitForm()">
                        <div class="form">
                            <div class="avatarWrap">
                                <div class="form-group">
                                    <label>Select Avatar</label>
                                    <ul class="avtarIcon">
                                        <li [className]="avatar1 ? 'active' : 'inactive'">
                                            <label class="avatars color_{{selectedColorId}}">
                                                <input [checked]="avatar1" type="radio" name="avatar"
                                                    (click)="toggleAvatar(1)" />
                                                <img src="assets/images/avatar_w1.svg" />
                                                <span *ngIf="avatar1" class="checkboxWhite">
                                                    <img src="assets/images/checkbox.svg" />
                                                </span>
                                            </label>
                                        </li>
                                        <li [className]="avatar2 ? 'active':''">
                                            <label class="avatars color_{{selectedColorId}}"> <input type="radio"
                                                    [checked]="avatar2" name="avatar" (click)="toggleAvatar(2)" /> <img
                                                    src="assets/images/avatar_w2.svg" /><span class="checkboxWhite"
                                                    *ngIf="avatar2"><img src="assets/images/checkbox.svg" /></span>
                                            </label>
                                        </li>
                                        <li [className]="avatar3 ? 'active' : ''">
                                            <label class="avatars color_{{selectedColorId}}"> <input type="radio"
                                                    name="avatar" (click)="toggleAvatar(3)" [checked]="avatar3" /> <img
                                                    src="assets/images/avatar_w3.svg" /> <span class="checkboxWhite"
                                                    *ngIf="avatar3"><img
                                                        src="assets/images/checkbox.svg" /></span></label>
                                        </li>
                                        <li [className]="avatar4 ? 'active' : ''">
                                            <label class="avatars color_{{selectedColorId}}"> <input type="radio"
                                                    [checked]="avatar4" name="avatar" (click)="toggleAvatar(4)" /> <img
                                                    src="assets/images/avatar_w4.svg" /><span class="checkboxWhite"
                                                    *ngIf="avatar4"><img src="assets/images/checkbox.svg" /></span>
                                            </label>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div class="colour_picker">
                                <div class="form-group">
                                    <label>Select Avatar Color</label>
                                    <ul class="colorPicker">
                                        <li [className]="color1 ? 'active':''">
                                            <label class="colors color_1 position-relative"> <input type="radio"
                                                    name="colors" [checked]="color1"
                                                    (click)="toggleColor(0, '#f36768')" /><img
                                                    src="assets/images/ellipse_1.svg" />
                                                <span *ngIf="color1"><img
                                                        src="assets/images/white-check.svg"></span></label>
                                        </li>

                                        <li [className]="color2 ? 'active':''">
                                            <label class="colors color_2 position-relative"> <input type="radio"
                                                    name="colors" [checked]="color2"
                                                    (click)="toggleColor(1, '#b865cf')" />
                                                <img src="assets/images/ellipse_2.svg" />
                                                <span *ngIf="color2"><img src="assets/images/white-check.svg"></span>
                                            </label>
                                        </li>

                                        <li [className]="color3 ? 'active':''">
                                            <label class="colors color_3 position-relative"> <input type="radio"
                                                    name="colors" [checked]="color3"
                                                    (click)="toggleColor(2, '#60d07b')" />
                                                <img src="assets/images/ellipse_3.svg" /> <span *ngIf="color3"><img
                                                        src="assets/images/white-check.svg"></span></label>
                                        </li>

                                        <li [className]="color4 ? 'active':''">
                                            <label class="colors color_4 position-relative">
                                                <input [checked]="color4" type="radio" name="colors"
                                                    (click)="toggleColor(3, '#7465cf')" /> <img
                                                    src="assets/images/ellipse_4.svg" />
                                                <span *ngIf="color4"><img
                                                        src="assets/images/white-check.svg"></span></label>
                                        </li>

                                        <li [className]="color5 ? 'active':''">
                                            <label class="colors color_5 position-relative">
                                                <input type="radio" [checked]="color5" name="colors"
                                                    (click)="toggleColor(4, '#fcb241')" />
                                                <img src="assets/images/ellipse_5.svg" />
                                                <span *ngIf="color5">
                                                    <img src="assets/images/white-check.svg"></span>
                                            </label>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group">
                                        <label>First Name</label>
                                        <input type="text" class="form-control" placeholder="First name" formControlName="firstName" [(ngModel)]="firstName" maxlength="20" />
                                        <div *ngIf="firstNameErrorMsg" class="warnings pl-1">{{firstNameErrorMsg}}</div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label>Last Name</label>
                                        <input type="text" class="form-control" placeholder="Last name" formControlName="lastName" [(ngModel)]="lastName" maxlength="20" />
                                        <div *ngIf="lastNameErrorMsg" class="warnings pl-1">{{lastNameErrorMsg}}</div>
                                    </div>
                                </div>
                                </div>
                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label>Work Email</label>
                                            <div class="emailWrap">
                                                <input type="text" class="form-control" [value]="userData?.email" disabled />
                                                <span class="activeAccount"><img src="assets/images/white-check.svg"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label>Username</label>
                                            <input type="text" class="form-control" placeholder="Username" formControlName="userName" [(ngModel)]="userName" maxlength="20" readonly/>
                                            <div *ngIf="lastNameErrorMsg" class="warnings pl-1">{{userNameError}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label>Job Function</label>
                                            <select class="form-control" formControlName="job_fun" [ngClass]="createProfileForm.controls.job_fun?.value ? ' ':'graytxt'">
                                                <option value="" name="select Job title" disabled selected="Select Job Title">Select
                                                    Job Function</option>
                                                <option *ngFor="let job of job_functionList" [value]="job">{{job}}</option>
                                            </select>
                                            <div *ngIf="jobTypeErrorMsg" class="warnings pl-1">{{jobTypeErrorMsg}}</div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label>Job Title</label>
                                            <select class="form-control" formControlName="roleType" [ngClass]="createProfileForm.controls.roleType?.value ? ' ':'graytxt'">
                                                <option value="" name="select Job title" disabled selected="Select Job Title">Select
                                                    Job Title</option>
                                                <option *ngFor="let job of jobTitles" [value]="job">{{job}}</option>
                                            </select>
                                            <div *ngIf="roleTypeErrorMsg" class="warnings pl-1">{{roleTypeErrorMsg}}</div>
                                        </div>
                                    </div>
                                </div>
                            <div class="submitWrap mt-0">
                                <button class="btn btn-primary" [class.disable]="createProfileForm?.invalid" [disabled]="createProfileForm?.invalid"> CREATE PROFILE </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <div class="footerWrap">
        <div class="copyright">
            <a class="_logo_a mt-2" href="https://www.guidewise.io/" target="_blank" rel="noopener noreferrer">
                <img class="_logo" src="../../../assets/images/guidewise_primary mark_final.jpg">
            </a>
            <span>Growthdrivers R3.0.0</span>
            <p>&copy; 2021 - 2025 Guidewise</p>
        </div>
    </div>
</div>