import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { LoginResponse } from './login-response';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { apiEndPoint } from '../../shared/apiEndPoints';
import { AdminService } from '../../services/admin.service';
import { loginModel } from '../../shared/models/login-model';
import { NotificationService } from '../../../app/dashboard/notification/notification.service';
import * as CryptoJS from 'crypto-js';
import * as CONSTANT from './../../services/constants';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  public loading = false;
  showError = false;
  isRemember: boolean = false;
  emailWarningMsg: string;
  passwordWarningMsg: string;
  emailStatus: string;
  passwordStatus: string;
  commonMsg: string;
  notificationCount: any;
  currentUrl: string;
  subscription: Subscription;
  loginForm: FormGroup = null;
  loginModel = new loginModel();
  passwordType: string = 'password';
  passwordShown: boolean = false;
  userNotFound: boolean = false;
  modalRef: BsModalRef;
  accessToken:any;
  newPin:any;
  confirmPin:any;
  notSameError=false;
  newPinError=false;
  confirmPinError=false;
  config = {
    allowNumbersOnly: true,
    length: 4,
    isPasswordInput: true,
    autofocus: true,
    placeholder: '',
    inputStyles: {
      'font-size': '20px',
    }
  };
  config1 = {
    allowNumbersOnly: true,
    length: 4,
    isPasswordInput: true,
    disableAutoFocus: true,
    placeholder: '',
    inputStyles: {
      'font-size': '20px',
    }
  };
  ShowPin: boolean =true;
  captchaError = false;
  token: string | undefined;
  warningMsg = '';
  baseUrl: string = environment.backend.baseURL;
  @ViewChild('createPinModel') createPinModel: TemplateRef<any>;
  constructor(private notificationService: NotificationService, private readonly authService: AuthService, private readonly router: Router, private api: AdminService, private modalService: BsModalService, public formBuilder: FormBuilder) {

  }

  ngOnInit(): void {
    let token = localStorage.getItem("access_token");
    if (token) {
      this.router.navigate(['/dashboard']);
    }
    this.loginForm = new FormGroup({
      email: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required),
      checkbox: new FormControl(false)
    })
    this.token = undefined;
    let sessionData = localStorage.getItem('rememberMeGd');
    if (sessionData) {
      this.decryptUsingAES256(localStorage.getItem('rememberMeGd'));
    }

    if (sessionStorage.getItem('tempLogin') === '1' && localStorage.getItem('access_token') !== 'null') {
      this.authService.login(localStorage.getItem('access_token'));
      this.authService.setProfileStatus(true);
      this.router.navigate(['/dashboard']);
    } else if (localStorage.getItem('rememberMeGd') == 'true' && localStorage.getItem('access_token') !== 'null') {
      this.authService.login(localStorage.getItem('access_token'));
      this.authService.setProfileStatus(true);
      this.router.navigate(['/dashboard']);
    } else if (localStorage.getItem('access_token') === 'null') {
      sessionStorage.removeItem('tempLogin');
      sessionStorage.removeItem('lastRoute');
      this.router.navigate(['/login']);
    }

  }

  handleForm(): void {
    this.emailStatus = this.loginForm.controls.email.status
    this.passwordStatus = this.loginForm.controls.password.status;
    const userEmail = this.loginForm.value.email;
    const password = this.loginForm.value.password;
    const rememberMe = this.isRemember;
    if (this.emailStatus === 'VALID' && this.passwordStatus === 'VALID' && this.token) {
      this.captchaError = false;
      this.userNotFound = false;
      this.emailWarningMsg = '';
      this.passwordWarningMsg = '';
      sessionStorage.removeItem('tempLogin');
      sessionStorage.removeItem('lastRoute');
      this.loginUser(userEmail, password, rememberMe);
    }
    else if (this.emailStatus === 'INVALID') {
      this.userNotFound = false;
      if (userEmail.length == 0) {
        this.emailWarningMsg = 'Please enter username or work email address';
      } else if (this.loginForm.controls.email.status === 'INVALID') {
        this.warningMsg = 'Please enter a valid email address';
        setTimeout(() => {
          this.warningMsg = '';
        }, 2000);
      } else {
        this.emailWarningMsg = 'Please enter correct Email/Username to login';
      }
      setTimeout(() => {
        this.emailWarningMsg = '';
      }, 2000);
    } if (this.passwordStatus === 'INVALID') {
      this.userNotFound = false;
      this.passwordWarningMsg = 'Please enter password';
      setTimeout(() => {
        this.passwordWarningMsg = '';
      }, 2000);
    } if(!this.token) {
      this.captchaError = true;
      setTimeout(() => {
        this.captchaError = false;
      }, 2000);
    }
  }

  loginUser(userEmail: string, password: string, rememberMe: boolean): void {
    let requestData = {
      email: userEmail,
      user_type: '2',
      password: password,
      mobile_unique_id: '123456',
      device_token: '2145487454659',
      device_type: '1',
      is_keep_me_signed_in: rememberMe ? 1 : 0
    }
    this.loading = true;
    this.subscription = this.api.postData(apiEndPoint.LOGIN, requestData)
      .subscribe(async (res: LoginResponse) => {
        switch (res.status) {
          case 400:
            if (res.message) {
              this.commonMsg = res.message.toString();
            } else {
              this.commonMsg = res.data.toString();
            }
            break;
          case 401:
            this.commonMsg = 'Please enter correct password to login';
            break;
          case 404:
            this.userNotFound = true;
            break;
          default:
            this.isRemember ? this.encryptUsingAES256(JSON.stringify(requestData)) : localStorage.removeItem('rememberMeGd');
            this.loading = false;
            this.accessToken= res.data.access_token;
            let login_pin = JSON.stringify(res.data);
            let checkPin = JSON.parse(login_pin);
            if (checkPin.is_login_pin == '0') {
              this.pinModel();
              //localStorage.setItem('gdUserInfo', JSON.stringify(res.data));

            } else {
              this.authService.login(res.data.access_token);
              localStorage.setItem('gdUserInfo', JSON.stringify(res.data));
              localStorage.setItem('role',JSON.stringify(res.data.role))
              localStorage.setItem('user_type',JSON.stringify(res.data.user_type))
              localStorage.setItem('companyTitle',JSON.stringify(res.data.company.name))
              if(res.data.pin_attempts > 0) {
                localStorage.setItem('pinAttempts', JSON.stringify(res.data.pin_attempts));
              }
              localStorage.setItem('enablePin', JSON.stringify(res.data.is_pin_enable == 1? true: false ));
              this.redirectNewPage(res.data.profile_status)
            }
        }

      },
        (error: HttpErrorResponse) => {
          this.passwordWarningMsg = '';
          this.emailWarningMsg = '';
          switch (error.status) {
            case 401:
              this.commonMsg = 'Please enter correct password to login';
              break;
            case 404:
              this.userNotFound = true;
              break;
          }
        })
  }

  redirectNewPage(type) {
    if (type === "1") {
      this.authService.setProfileStatus(true);
      this.router.navigate(['/dashboard']);
      this.getnotificationData();
    } else {
      this.authService.setProfileStatus(false);
      this.router.navigate(['/create-profile']);
      this.getnotificationData();
    }
  }
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

  }

  encryptUsingAES256(value) {
    return new Promise((resolve, reject) => {
      let cipherText = CryptoJS.AES.encrypt(value, "123456789");
      localStorage.setItem('rememberMeGd', cipherText);
      resolve(cipherText);
    });

  }
  decryptUsingAES256(value) {
    try {
      let bytes = CryptoJS.AES.decrypt(value.toString(), "123456789");
      let plaintext = bytes.toString(CryptoJS.enc.Utf8);
      let rememberedData = JSON.parse(plaintext);
      this.loginModel.email = rememberedData.email || '';
      this.loginModel.password = rememberedData.password || '';
      this.isRemember = true;
      this.loginForm.value.checkbox = true;
    } catch (e) {
    }
  }

  changeRememberMe(event) {
    this.isRemember = event.target.checked;
  }
  public togglePassword() {
    if (this.passwordShown) {
      this.passwordShown = false;
      this.passwordType = 'password';
    } else {
      this.passwordShown = true;
      this.passwordType = 'text';

    }
  }

  getnotificationData() {
    let params = new HttpParams();
    params = params.append('access_token', localStorage.getItem('access_token'));
    this.api.getData(`${this.baseUrl}/api/v1/notifications`, params).subscribe((res: any) => {
      localStorage.setItem('notification', res.data.newCount);
      console.log('noti');
    },
      (error: HttpErrorResponse) => {
        console.log(error);
      },
      () => {

      })
  }

  closePopup() {
    this.modalRef.hide();
  }
  pinModel() {
    this.modalRef = this.modalService.show(this.createPinModel, {
      animated: true,
      backdrop: 'static',
      class: 'w-3'
    });
  }
  otpSubmit() {
    if(!this.newPin || this.newPin.length<4){
      this.newPinError=true;
      return false
    }
    if(!this.confirmPin || this.confirmPin.length<4){
      this.confirmPinError=true;
      return false
    }
    if(this.confirmPin != this.newPin){
      this.newPin = "";
      this.confirmPin = "";
      this.notSameError=true;
      this.ShowPin = false;
      setTimeout(() => {
        this.ShowPin = true;
      }, 0);
      return false
    }
    const profileObj = {
      access_token: this.accessToken,
      "login_pin" : this.confirmPin.toString()
    };
    this.api.postData(apiEndPoint.CREATE_PROFILE, profileObj)
      .subscribe(async (res) => {
        this.authService.login(this.accessToken);
        localStorage.setItem('gdUserInfo', JSON.stringify(res.data));
        this.closePopup(); 
        this.redirectNewPage(res.data.profile_status);

      },
        (error: HttpErrorResponse) => {
          console.log(error);
        })
   
  }
  newPinChange(e) {
    this.newPin= e;
    this.newPinError=false;
    this.notSameError=false;
  }
  confirmPinChange(e){
    this.confirmPin= e;
    this.confirmPinError=false;
    this.notSameError=false;
  }


}


