import { CommonModule, DatePipe } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSliderModule } from '@angular/material/slider';
import { provideRoutes, RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxEchartsModule } from 'ngx-echarts';
import { CoreModule } from '../core/core.module';
import { EmotionIntensityComponent } from '../dashboard/create-entry/emotion-intensity/emotion-intensity.component';
import { SliderComponent } from '../dashboard/create-entry/emotion-intensity/slider/slider.component';
import { EmotionComponent } from '../dashboard/create-entry/emotion/emotion.component';



@NgModule({
  declarations: [
    EmotionComponent,
    EmotionIntensityComponent,
    SliderComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    CoreModule,
    MatSliderModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts')
    }),
    // AngularMultiSelectModule,
     // TagInputModule,
    FormsModule,   
    ReactiveFormsModule,
    NgbModule,
  ],
  providers:[
    DatePipe
  ],
  exports: [EmotionComponent,EmotionIntensityComponent,
    SliderComponent,
],
  entryComponents: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule { }
