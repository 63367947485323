import { Injectable } from '@angular/core';
import { HttpRequest, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AdminService } from './admin.service';
import { AuthService } from '../../app/core/services/auth.service';
import { Router } from '@angular/router';
import { catchError, finalize } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})

export class InterceptorService implements HttpInterceptor {
    private activeRequests = 0;
    constructor(private admin: AdminService, private readonly authService: AuthService, private readonly router: Router) { }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.activeRequests++;
        // Clone the request to add the new header.
        let token = localStorage.getItem("authorization");
        if (token) {
            req = req.clone({ headers: req.headers.set("authorization", token) });
        }
        if (this.admin.showLoader)
            this.admin.loader(true);
        else
            this.admin.showLoader = true;

        return next.handle(req).pipe(
            catchError((error) => {
                // Handle the error here
                this.admin.showLoader = false;
                this.admin.loader(false);
                console.log(error?.error?.message,'err');
                
                if (error?.status == 404 && error?.error?.status == 404) {
                    this.authService.logout(0);
                    this.admin.errorAlert(error?.error?.message, 'Your session has been expired, please login again.')
                }
                else {
                    
                    // if (error?.error?.status != 405 && error?.status != 405 && error?.error?.status != 423 && error?.status != 423){  
                     this.admin.errorAlert(error?.error?.message, false);
                    // }
                }
                return throwError(error);
              }),
            finalize(() => {
                this.activeRequests--;
                if (this.activeRequests === 0) {
                    // Hide loading animation here
                    if (!req.url.includes('https://twinword-emotion-analysis-v1.p.rapidapi.com/analyze/?tex')) {
                        this.admin.showLoader = false;
                        this.admin.loader(false);
                    }
                }
            }),
        );
    }
}
