<div class="main_container terms pt-0">
    <div class="container">
        <div class="privacy-policy">
            <div class="title-bar">
                <h1 *ngIf="!showBack">Terms of Use <span> Last updated: June 21, 2023 </span></h1>
                <h1 *ngIf="showBack"><a class="backArrow pointer" (click)="goBack()">Terms of Use <span> Last updated: June 21, 2023 </span>
                </a></h1>
            </div>

            <h3><strong>Terms of Use Agreement </strong></h3>
            <p>
                PLEASE READ THESE TERMS OF USE ("TERMS") CAREFULLY BEFORE ACCESSING OR PARTICIPATING IN ANY WEBSITE, BLOG, ONLINE PROGRAM OR CONSULTATION, LIVE OR RECORDED WEBINAR, MAILING LIST, TRANSACTION, OR OTHER ONLINE FORUM AVAILABLE AT https://www.guidewise.io/,
                OR OTHER ONLINE SERVICE OFFERED THROUGH GUIDEWISE, INCLUDING THE GROWTHDRIVERSTM SOFTWARE APP (COLLECTIVELY, THE "SITE" OR "SITES"). BY USING AND PARTICIPATING IN THE SITES, YOU ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS AND AGREE
                TO BE BOUND BY AND COMPLY WITH THEM. IF YOU DO NOT AGREE TO BE BOUND BY THESE TERMS, PLEASE PROMPTLY STOP ALL ACCESS AND USE OF THE SITES. GUIDEWISE RESERVES THE RIGHT TO MODIFY THESE TERMS AT ANY TIME AND WILL PUBLISH NOTICE OF ANY
                SUCH MODIFICATIONS ONLINE AT THIS SITE OR ELSEWHERE ONLINE. BY CONTINUING TO ACCESS ANY SITE AFTER NOTICE OF SUCH MODIFICATIONS HAVE BEEN PUBLISHED, YOU AGREE TO BE BOUND BY THEM.
            </p>
            <h3><strong> Disclaimer </strong></h3>
            <p>
                Sites may include unmoderated reviews, profiles, blogs, forums, and other content containing the personal opinions and other expressions of the visitors and users who post entries on a wide range of topics ("User Content"). Neither the User Content, nor
                links to other websites, are screened, approved, or endorsed by Guidewise (“we”, “our” or “us”). Except as expressly noted, Guidewise is not a publisher of any of the User Content, or of any content that may be available through links
                to and from them, and with respect to User Content is acting solely as an internet service provider. If you feel you might be offended by User Content on the Sites, you should not continue.
            </p>

            <h3><strong> Privacy Policy </strong></h3>

            <p> Please read our Privacy Policy at <a href="https://www.guidewise.io/privacy-policy">https://www.guidewise.io/privacy-policy</a>. The Privacy Policy is incorporated herein by reference.</p>

            <h3><strong> Program Policies </strong></h3>

            <p> Additional terms may apply to certain educational offerings provided for a fee (“Programs”), such as Elite Entrepreneur Incubator© (“Program Policies”). You will be notified upon your enrollment in any Program of any Program Policies applicable
                to you. Please be sure to read such Program Policies. They are incorporated herein by reference. To the extent of any conflict between such Program Policies and these Terms, the Program Policies will control. </p>


            <h3><strong> Ownership and Permission to Use Materials </strong></h3>

            <p> You acknowledge that the Site, all material and applications made available to you by GUIDEWISE, including all written materials, forms, formulas, ideas, business systems, exercises to be completed, personal questionnaires and evaluations,
                methodologies, tools, documentation, know-how, show-how, programs, software, trade secrets, and other proprietary information, developed or presented by GUIDEWISE or its third party providers (“GUIDEWISE Content”), whether provided
                or made generally available by GUIDEWISE through the Site without a fee (“Site Materials”) or provided pursuant to your enrollment in any Program (“Program Materials”), remains the sole and exclusive property of GUIDEWISE and/or such
                providers. </p>


            <p> Program Materials. In consideration of your enrollment in any Program, payment of the applicable fees therefore as and when due, and compliance with the Terms, GUIDEWISE hereby grants to you a personal, nonexclusive license to access and
                use, including through your enrolled business and enrolled employees, the Program Materials for your educational and internal business purposes only. You will not permit any enrolled business or enrolled employee to provide any copy
                of any Program Materials to any person other than enrolled employees, including by any electronic means which contain or include any Program Materials. The foregoing license is personal to you and excludes any right or permission to
                introduce, train and educate persons, whether sub-contractors, independent contractors, or other businesses or organizations, other than your enrolled businesses and enrolled employees. </p>


            <p> Site License and Site Materials. In consideration of your compliance with the Terms, GUIDEWISE grants you a personal, non-exclusive license to access and use the Site Materials. You may access and use the Site and Site Materials only for
                your own personal, non-commercial, educational use. You may not otherwise copy, reproduce, retransmit, distribute, publish, make available copies of any Site Materials, commercially exploit or otherwise transfer any such Site Materials
                for the use of others. </p>


            <p> License Limitations. The foregoing licenses are non-assignable, nontransferable and do not include the right to sublicense. You may not use, display or otherwise make available Program Materials or Site Materials, in an electronic format
                that enables it to be downloaded or distributed to any unenrolled third party via mobile devices or shared in a file sharing arrangement, or by any other means. </p>

            <p> License Limitations. The foregoing licenses are non-assignable, nontransferable and do not include the right to sublicense. You may not use, display or otherwise make available Program Materials or Site Materials, in an electronic format
                that enables it to be downloaded or distributed to any unenrolled third party via mobile devices or shared in a file sharing arrangement, or by any other means. </p>

            <h3><strong> Rules for Online Conduct </strong></h3>
            <p>You may use Sites for legal purposes only. Furthermore, you agree that, if a third party claims that any material you have contributed to a Site is unlawful, you will bear the burden of establishing that the material complies with all
                applicable laws. You may not use GUIDEWISE program resources or Sites to disseminate unsolicited advertising or promotional material of any kind or for any purpose prohibited by these Rules for Online Conduct.</p>

            <p> You agree to use the Sites in accordance with all applicable laws and these Terms. You further agree that you will not email or post any of the following content anywhere on the Site: </p>

            <ul>
                <li> You agree to use the Sites in accordance with all applicable laws and these Terms. You further agree that you will not email or post any of the following content anywhere on the Site: </li>

                <li> Harassing statements or content that violates federal or state law; </li>
                <li> Content that discusses illegal activities with the intent to commit them;</li>
                <li> Content that infringes another's intellectual property, including, but not limited to, copyrights, trademarks or trade secrets; </li>
                <li> Material that contains obscene (i.e. pornographic) language or images; </li>
                <li> Advertising or any form of commercial solicitation; or </li>
                <li> Content that is otherwise unlawful.</li>

            </ul>


            <p> Copyrighted material, including without limitation software, graphics, text, photographs, sound, video and musical recordings, may not be placed on the Site without the express permission of the owner of the copyright in the material,
                or other legal entitlement to use the material. </p>

            <p> By posting User Content and information on the Site, you give permission for GUIDEWISE display the User Content on the Site and make use of such content for any lawful purpose, subject to GUIDEWISE's obligations to protect your privacy
                and personal information as set forth in the Privacy Notice. Without creating any obligation of GUIDEWISE to screen or monitor User Content, we reserve the right to remove content which we determine in violate the above rules. To report
                potential violations of online conduct, please flag the content as inappropriate or send an email to <a href="mailto:support@guidewise.io"> support@guidewise.io </a>. </p>

            <p> You also agree that you will not access or attempt to access any other user's account, or misrepresent or attempt to misrepresent your identity while using the Sites. </p>

            <h3><strong> Links to Other Sites </strong></h3>
            <p> The Sites may include hyperlinks to websites maintained or controlled by others. GUIDEWISE is not responsible for and does not routinely screen, approve, review, or endorse the contents of or use of any of the products or services that
                may be offered at these websites.</p>


            <h3><strong> Choice of Law/Forum Selection </strong></h3>
            <p>These Terms shall be interpreted in accordance with and governed by the substantive laws of the Commonwealth of Pennsylvania, without regard to any principles of conflicts of law thereof. The courts of Pennsylvania, County of Lancaster,
                shall have the exclusive jurisdiction in any controversy relating to or arising out of these Terms or the Site. Any claim or cause of action arising out of or related to these Terms, or the Site must be filed no later than one year
                after such claim has accrued. GUIDEWISE shall be entitled to an injunction, preliminary injunction, temporary restraining order, or other form of equitable relief restraining any breach or violation of these Terms, and to exercise
                all other legal and equitable remedies afforded to it by law, and to recover from you reasonable costs and attorneys' fees incurred by GUIDEWISE in exercising any such remedy. </p>


            <h3><strong> Disclaimer of Warranty/Limitation of Liability </strong></h3>

            <p><strong>THESE SITES AND ANY INFORMATION, PRODUCTS OR SERVICES THEREIN ARE PROVIDED "AS IS" WITHOUT
                    WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION, THE IMPLIED
                    WARRANTIES OF MERCHANTABILITY, FITNESS FOR USE OF A PARTICULAR PURPOSE, OR NONINFRINGEMENT.
                </strong></p>

            <p><strong> GUIDEWISE does not warrant, and hereby disclaims any warranties, either express or implied,
                    (i) with respect to the accuracy, adequacy or completeness of any Site, information obtained
                    from a Site, link to a Site, or any results derived from the use of the Sites, (ii) that Sites
                    will operate in an uninterrupted or error-free manner, or (iii) that Sites are free of viruses
                    or other harmful components. You acknowledge that GUIDEWISE makes no representation and
                    expressly disclaims that any GUIDEWISE Content, advice, or recommendations will increase sales,
                    margins, profits, or efficiency, reduce costs or expenses, improve operations or
                    competitiveness, or have any other particular effect. Use of all GUIDEWISE Content is at your
                    own risk. </strong></p>


            <p> <strong> YOU AGREE THAT GUIDEWISE WILL NOT BE LIABLE TO YOU OR ANY OTHER THIRD PARTY FOR ANY LOSS OR
                    DAMAGES, EITHER ACTUAL OR CONSEQUENTIAL, ARISING OUT OF OR RELATING TO THESE TERMS, OR TO YOUR
                    (OR ANY THIRD PARTY'S) USE OR INABILITY TO USE A SITE, OR TO YOUR PLACEMENT OF CONTENT ON A
                    SITE, OR TO YOUR RELIANCE UPON INFORMATION OBTAINED FROM OR THROUGH A SITE. IN PARTICULAR,
                    GUIDEWISE WILL HAVE NO LIABILTY FOR ANY CONSEQUENTIAL, INDIRECT, PUNITIVE, SPECIAL OR INCIDENTAL
                    DAMAGES, WHETHER FORESEEABLE OR UNFORESEEABLE, (INCLUDING, BUT NOT LIMITED TO, CLAIMS FOR
                    DEFAMATION, ERRORS, LOSS OF DATA, OR INTERRUPTION IN AVAILABILITY OF DATA), ARISING OUT OF OR
                    RELATING TO THESE TERMS, YOUR USE OR INABILITY TO USE A SITE OR GUIDEWISE MATERIALS, OR ANY
                    PURCHASES ON OR THROUGH A SITE, OR YOUR PLACEMENT OF CONTENT ON A SITE, OR TO YOUR RELIANCE UPON
                    INFORMATION OBTAINED FROM OR THROUGH A SITE OR ANY GUIDEWISE SERVICE, WHETHER BASED IN CONTRACT,
                    TORT, STATUTORY OR OTHER LAW, EXCEPT ONLY IN THE CASE OF. NOTWITHSTANDING ANY OTHER PROVISION,
                    GUIDEWISE’s LIABILITY FOR ANY CLAIM, LOSS OR LIABILITY CONNECTED WITH THIS THESE TERMS, ANY
                    AGREEMENT OR THE SERVICES, INCLUDING BREACH OF AGREEMENT, WARRANTY, OR NEGLIGENCE (EXCLUDING
                    ONLY CLAIMS BASED ON DEATH OR BODILY INJURY WHERE AND ONLY TO THE EXTENT THAT APPLICABLE LAW
                    REQUIRES SUCH LIABILITY), SHALL IN NO CASE EXCEED THE ENROLLMENT FEES PAID BY CLIENT DURING THE
                    12 MONTH PERIOD PRECEDING THE EVENT GIVING RISE TO THE CLAIM. </strong> </p>


            <p> GUIDEWISE makes no representation regarding your ability to transmit and receive information from or through the Sites and you agree and acknowledge that your ability to access the Sites may be impaired. GUIDEWISE disclaims any and all
                liability resulting from or related to such events. </p>


            <h3><strong> Trademarks </strong></h3>
            <p> The logo, name, and all graphics of GUIDEWISE on the Sites, including Growthdrivers and Elite Entrepreneur Incubator are trademarks of GUIDEWISE or its affiliates. Use, reproduction, copying or redistribution of trademarks, without the
                written permission of GUIDEWISE or its affiliates is prohibited. All other trademarks or service marks appearing on the Site are the marks of their respective owners. </p>

            <h3><strong> Indemnification </strong></h3>

            <p><strong> You agree to indemnify and hold GUIDEWISE harmless from any claims, losses, or damages,
                    including legal fees, resulting from your violation of these Terms, your use of a Site or your
                    placement of any content onto a Site, and to fully cooperate in GUIDEWISE’s defense against any
                    such claims. </strong></p>


            <h3><strong> Your Account </strong></h3>
            <p> You are solely responsible for maintaining the confidentiality of your account any password or other credentials issued to you, if any, and for restricting access to your computer, and you agree to accept responsibility for all activities
                that occur under your account or password. If you are under 18, you may purchase products or services only through a parent or guardian. You agree that billing and registration information you provide on the Sites will be accurate
                and complete. GUIDEWISE and its affiliates reserve the right to refuse service, terminate accounts, remove, or edit content, or cancel orders in their sole discretion. </p>


            <h3><strong> Other </strong></h3>

            <p> The Sites may contain errors and omissions relating to product description, pricing and availability. GUIDEWISE reserves the right to correct errors or omissions without prior notice. GUIDEWISE also reserves the right to cancel any offered
                product or service in the event of an error or omission in the description, including price, unavailability, or other reason. </p>

            <h3><strong> General </strong></h3>

            <p> These Terms constitute the entire agreement between you and GUIDEWISE and its affiliates with respect to the subject matter herein and supersedes any and all prior or contemporaneous oral or written agreements.</p>

            <h3><strong> Copyright Complaints </strong></h3>

            <p> GUIDEWISE respects the intellectual property rights of others. If you believe your copyright has been violated on a GUIDEWISE site, please contact <a href="mailto:support@guidewise.io">
                    support@guidewise.io</a>. </p>


        </div>
    </div>
</div>