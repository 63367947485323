import {environment} from '../../environments/environment';

const baseURL = environment.backend.baseURL;

export const apiEndPoint = {
    LOGIN: baseURL + '/api/v1/user/login',
    LOG_OUT: baseURL + '/api/v1/user/logout',
    FORGOT_PASSWORD: baseURL + '/api/v1/user/forgot',
    CREATE_PROFILE: baseURL +'/api/v1/user/profile',
    GENERATE_PASSWORD: baseURL +'/api/v1/user/changePassword',
    RESET_PASSWORD: baseURL +'/api/v1/user/changePassword',
    CHANGE_PASSWORD: baseURL +'/api/v1/user/resetPassword',
    CONTACT: baseURL +'/api/v1/contact',
    SUPPORT: baseURL +'/api/v1/contact/supportResources',
    LINKVERFY: baseURL +'/api/v1/user/link_verification',
    
}
