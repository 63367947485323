<div class="wrapper pl-0">
    <!-- loginHeader end  -->
    <div class="main_container terms pt-0">
        <div class="container">
            <div class="title-bar pt-0">
                <h1 *ngIf="!showBack"> Privacy Policy</h1>
                <h1 *ngIf="showBack"><a class="backArrow pointer" (click)="goBack()">Privacy Policy</a></h1>
            </div>
            <div class="privacy-policy">
                <h1>Guidewise© Privacy Policy <span>Last updated: June 21, 2023</span></h1>

                <p>
                    Guidewise© (“we,” “our,” or “us”) offers educational programs and other services to business owners and entrepreneurs to build the skills and knowledge they need to reach their professional goals. We are committed to protecting the personal information
                    and supporting the privacy of individuals who enroll in our programs or connect with us.
                </p>

                <p>
                    When you visit our website <a href="https://www.guidewise.io/">https://www.guidewise.io/</a>, or use our software applications, such as Growthdrivers© (collectively, the “Site”) or use our services, you trust us with your personal
                    information. We take your privacy very seriously. This Privacy Notice explains how we manage and use the personal information we collect about our customers and site visitors, and our obligations and your rights relating to this information.
                    If there are any terms in this Privacy Notice that you do not agree with, please discontinue use of our site and our services.
                </p>

                <p>
                    This privacy policy applies to all information collected through GUIDEWISE and becomes part of any other agreement between you and GUIDEWISE relating to, our Site, and/or any related services, sales, marketing, or events.
                </p>

                <p>Please read this Privacy Notice carefully as it will help you make informed decisions about sharing your personal information with us.</p>

                <h3><strong> TABLE OF CONTENTS </strong></h3>

                <ol>
                    <li>INFORMATION THAT WE COLLECT</li>
                    <li>HOW WE USE YOUR INFORMATION</li>
                    <li>INFORMATION THAT MAY BE SHARED WITH THIRD PARTIES</li>
                    <li>COOKIES AND OTHER TRACKING TECHNOLOGIES</li>
                    <li>INFORMATION THAT MAY BE TRANSFERRED INTERNATIONALLY</li>
                    <li>THIRD-PARTY WEBSITES</li>
                    <li>HOW LONG WE KEEP YOUR INFORMATION</li>
                    <li>HOW WE KEEP YOUR INFORMATION SAFE</li>
                    <li>INFORMATION FROM MINORS</li>
                    <li>YOUR PRIVACY RIGHTS</li>
                    <li>FOR CALIFORNIA RESIDENTS WITH SPECIFIC PRIVACY RIGHTS</li>
                    <li>UPDATES TO THIS POLICY</li>
                    <li>HOW YOU CAN CONTACT US ABOUT THIS POLICY</li>
                </ol>

                <h3><strong>1. INFORMATION THAT WE COLLECT </strong></h3>

                <p><i>Information You Disclose to Us. </i></p>
                <p>
                    We collect personal information that you voluntarily provide to us when you register at the Site, express your interest through email or the Site in obtaining information about us or our services, enroll in our programs, subscribe to our services, or
                    otherwise contact us.
                </p>

                <p>The personal information that we collect depends on the context of your interactions with us and the Site, and the choices you make. The personal information we collect from you may include the following:
                </p>

                <p>
                    Biographic and Contact Data. We collect your first and last name, email address, postal address, phone number, business title and affiliations, educational background, social media contact information, emergency contact information and other similar data
                    for your representative business.
                </p>

                <p>Credentials. We collect passwords, password hints, and similar security information used for authentication and account access.</p>

                <p>
                    Payment Data. We collect data necessary to process your payment if you make purchases, such as your payment instrument number (such as a credit card number), and the security code associated with your payment instrument. All payment data is stored by
                    our payment processor, and you should review its privacy policies and contact the payment processor directly to respond to your questions.
                </p>

                <p>For users of our site, we collect and process analytical information – aggregated information related to web visitor activity and email marketing actions.</p>

                <p>
                    We may also use third-party tracking services to track non-personally identifiable information about visitors to our site in the aggregate. The information we track is limited to web visitor activity and email marketing actions. Our systems also collect
                    information from cookies, web beacons, log files, pixel tags, local storage objects, and similar technologies.
                </p>

                <p>All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.</p>

                <p>Information automatically collected.</p>

                <p>
                    We automatically collect certain information when you visit, use, or navigate the Site. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address,
                    browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Site and other technical information. This information is primarily
                    needed to maintain the security and operation of our Site, and for our internal analytics and reporting purposes.
                </p>

                <p>Like many businesses, we also collect information through cookies and similar technologies. For more information about cookies, please see Section 4. COOKIES AND OTHER TRACKING TECHNOLOGIES.</p>

                <p><i>Information collected from other Sources </i></p>

                <p>
                    We may obtain information about you and your business from other sources, such as public databases, joint marketing partners, social media platforms (such as Facebook), as well as from other third parties. Examples of the information we receive from other
                    sources include employment or business profile information (your name, title, qualifications, gender, email, current city, state and country, profile picture URL and any other information that you choose to make public); marketing
                    leads and search results and links, including paid listings (such as sponsored links).
                </p>

                <h3><strong>2. HOW WE USE YOUR INFORMATION </strong></h3>

                <p>
                    We use personal information collected via our Site and services for a variety of business purposes described below. We process your personal information for these purposes in reliance on our legitimate business interests ("Business Purposes"), to enter
                    or perform a contract with you ("Contractual"), with your consent ("Consent"), and/or for compliance with our legal obligations ("Legal Reasons"). We indicate the specific processing grounds we rely on next to each purpose listed below.
                </p>

                <p>We use the information we collect or receive to:</p>
                <ul>
                    <li>Facilitate account creation and logon process with your Consent.</li>
                    <li>
                        Send you marketing and promotional communications, including participation in our services, for Business Purposes and with your Consent. We and/or our third-party marketing partners may use the personal information you send to us for our marketing purposes
                        if this is in accordance with your marketing preferences and Consent. Once you have provided Consent, you can opt-out of our marketing emails at any time (see Section 10. YOUR PRIVACY RIGHTS).
                    </li>

                    <li>
                        Send administrative information to you for Business Purposes, Legal Reasons and/or possibly Contractual. We may use your personal information to send you service and new feature information and/or information about changes to our terms, conditions, and
                        policies.
                    </li>

                    <li>
                        Fulfill and manage your enrollment, and program and service orders for Contractual reasons. We may use your information to fulfill and manage your orders, payments, returns, credits, and exchanges made through the Sites.
                    </li>
                    <li>
                        Post testimonials with your Consent. We post testimonials on our Site to the extent it may contain personal information. Prior to posting a testimonial, we will obtain your consent to use your name and testimonial. If you wish to update, or delete your
                        testimonial, please contact us at support@guidewise.io and be sure to include your name, testimonial location, and contact information.
                    </li>
                    <li>Request feedback for our Business Purposes and/or with your Consent. We may use your information to request feedback and to contact you about your participation in or use of our Site and services.
                    </li>

                    <li>Protect our Site for Business Purposes and/or Legal Reasons. We may use your information as part of our efforts to keep our Sites safe and secure (for example, for fraud monitoring and prevention).
                    </li>
                    <li>
                        Enable user-to-user or participant-to participant communications with your Consent. We may use your information to enable user-to-user or participant-to participant communications with each user's or participant’s Consent.
                    </li>
                    <li>Enforce our terms, conditions and policies for Business Purposes, Legal Reasons and/or Contractual purposes.</li>
                    <li>Respond to legal requests and prevent harm for Legal Reasons. If we receive a subpoena or other legal request, we may need to inspect the data we hold to determine how to respond.</li>

                    <li>
                        Conduct other Business Purposes. We may use your information for other Business Purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Sites, products, services,
                        marketing, and your experience.
                    </li>
                </ul>

                <h3><strong>3. INFORMATION THAT MAY BE SHARED WITH THIRD PARTIES </strong></h3>

                <p>We only share your personal information with third parties in the following situations:</p>

                <ul>
                    <li>
                        Compliance with Laws. We may disclose your information where we are legally required to do so to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal process, such as in response to a court order or a subpoena
                        (including in response to public authorities to meet national security or law enforcement requirements).
                    </li>

                    <li>
                        Vital Interests and Legal Rights. We may disclose your information where we believe it is necessary to investigate, prevent, or act regarding potential violations of our policies, suspected fraud, situations involving potential threats to the safety of
                        any person and illegal activities, or as evidence in litigation in which we are involved.
                    </li>

                    <li>
                        Vendors, Consultants and Other Third-Party Service Providers. We may share your data with third party vendors, service providers, contractors or agents who perform services for us or on our behalf and require access to such information to do that work.
                        Examples include payment processing, data analysis, email delivery, hosting services, customer service and marketing efforts. We may allow selected third parties to use tracking technology on the Sites, which will enable them to
                        collect data about how you interact with the Sites over time. This information may be used to, among other things, analyze and track data, determine the popularity of certain content and better understand online activity. Unless
                        described in this Policy, we do not share, sell, rent, or trade any of your information with third parties for their promotional purposes.
                    </li>

                    <li>
                        Business Transfers. We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.
                    </li>
                    <li>
                        Third-Party Advertisers. We may use third-party advertising companies to serve ads when you visit the Sites. These companies may use information about your visits to our website(s) and other websites that are contained in web cookies and other tracking
                        technologies to provide advertisements about goods and services of interest to you.
                    </li>
                    <li>
                        Affiliates. We may share your information with our affiliates, in which case we will require those affiliates to honor this privacy policy. Affiliates include our parent company and any subsidiaries, joint venture partners or other companies that we control
                        or that are under common control with us.
                    </li>
                    <li>Business Customers and Partners. We may share your information with our business customers and partners to offer you certain products, services, or promotions.</li>
                    <li>With your Consent. We may disclose your personal information for any other purpose with your Consent to the extent such use is described in the Consent.</li>
                    <li>
                        Other Users. When you share personal information (for example, by posting comments, contributions, or other content to the Sites) or otherwise interact with public areas of the Site, such personal information may be viewed by all users and may be publicly
                        distributed outside the Site.
                    </li>
                </ul>

                <h3><strong>4. COOKIES AND OTHER TRACKING TECHNOLOGIES </strong></h3>
                <p>
                    Cookies are text files placed on your computer to collect standard Internet log information and visitor behavior information. When you visit our Site and use our online services, we may collect information from you automatically through cookies or similar
                    technology (like web beacons and pixels). For further information, visit allaboutcookies.org. We use cookies in a range of ways to improve your experience on our website, including:
                </p>

                <ul>
                    <li>Keeping you signed in</li>
                    <li>Understanding how you use our website </li>
                </ul>

                <p>There are a number of different types of cookies, however, our website uses:</p>

                <ul>
                    <li>
                        Functionality – Our Company uses these cookies so that we recognize you on our website and remember your previously selected preferences. These could include what language you prefer and location you are in. A mix of first-party and third-party cookies
                        are used. You can set your browser not to accept cookies, and the above website tells you how to remove cookies from your browser. However, in a few cases, some of our website features may not function as a result.
                    </li>
                </ul>

                <h3><strong>5. INFORMATION THAT MAY BE TRANSFERRED INTERNATIONALLY </strong></h3>

                <p>
                    Our servers are located in the Unites States. If you are accessing our Site or participating in our service from outside the US, please be aware that your information may be transferred to, stored, and processed by us in our facilities and by those third
                    parties with whom we may share your personal information, in other countries. If you are a resident in the European Economic Area, then these countries may not have data protection or other laws as comprehensive as those in your country.
                    We will however take all necessary measures to protect your personal information in accordance with this privacy policy and applicable law.
                </p>

                <p>
                    European Commission's Standard Contractual Clauses: Such measures implementing the European Commission's Standard Contractual Clauses for transfers of personal information between us and our third-party providers, which require all such recipients to
                    protect personal information that they process from the EEA in accordance with European data protection laws are utilized for applicable transfers. We have implemented similar appropriate safeguards with our third-party service providers
                    and partners and further details can be provided upon request.
                </p>

                <h3><strong>6. THIRD-PARTY WEBSITES </strong></h3>
                <p>
                    The Site and email communications with us may contain advertisements from third parties that are not affiliated with us, and which may link to other websites or online services. We cannot guarantee the safety and privacy of data you provide to any third
                    parties. Any data collected by third parties is not covered by this privacy policy. We are not responsible for the content or privacy and security practices and policies of any third parties, including other websites, services or applications
                    that may be linked to or from the Site or such communications. You should review the policies of such third parties and contact them directly to respond to your questions.
                </p>

                <h3><strong>7. HOW LONG WE KEEP YOUR INFORMATION </strong></h3>
                <p>
                    We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy policy, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements), and subject
                    to your rights to in Section 10 – YOUR PRIVACY RIGHTS. No purpose in this policy will require us keeping your personal information for longer than the period of time in which you have an account with us plus 2 years past the termination
                    of your account; provided that under no circumstances do we have any obligation to keep information for a period exceeding that required under applicable law.
                </p>

                <p>
                    When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize it, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will
                    securely store your personal information and isolate it from any further processing until deletion is possible.
                </p>

                <h3><strong>8. HOW WE KEEP YOUR INFORMATION SAFE </strong></h3>

                <p>
                    We have implemented appropriate technical and organizational security measures designed to protect the security of any personal information we process. However, please also remember that we cannot guarantee that the internet itself is 100% secure. Although
                    we will do our best to protect your personal information, transmission of personal information to and from our Sites is at your own risk. You should only access the services within a secure environment.
                </p>

                <h3><strong>9. INFORMATION FROM MINORS </strong></h3>
                <p>
                    We do not knowingly solicit data from or market to children under 18 years of age. By using the Site, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Site.
                    If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data
                    we have collected from children under age 18, please contact us at <a href="mailto:support@guidewise.io">support@guidewise.io</a>.
                </p>

                <h3><strong>10. YOUR PRIVACY RIGHTS </strong></h3>

                <p>In some regions (like the European Economic Area), you have certain rights under applicable data protection laws. These may include:</p>
                <p>The right to be informed</p>
                <p>The right to access</p>
                <p>The right to rectification (correction)</p>
                <p>The right to erasure (to be forgotten)</p>
                <p>The right to restriction of processing</p>
                <p>The right to data portability</p>
                <p>The right to object</p>
                <p>The right to not be subject to automated decision making.</p>

                <p>
                    For assistance in exercising your rights, please use the contact details provided below in Section
                    <strong> 13 – HOW YOU CAN CONTACT US ABOUT THIS POLICY </strong>. We will consider and act upon any request in accordance with applicable data protection laws.
                </p>

                <p>
                    If we are relying on your consent to process your personal information, you have the right to withdraw your consent at any time. Please note however that this will not affect the lawfulness of the processing before its withdrawal.
                </p>

                <p>
                    If you are data subject of in the European Economic Area and you believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection supervisory authority. You can find their contact details
                    here:
                    <a href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm</a>
                </p>

                <p>Account Information. You may at any time review or change the information in your account or terminate your account by:</p>

                <ul>
                    <li>Logging into your account settings and updating your account; or</li>
                    <li>Contacting us using the contact information provided below</li>
                </ul>

                <p>
                    Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, some information may be retained in our files to prevent fraud, troubleshoot problems, assist with any investigations,
                    enforce any terms of use or contractual obligations and/or comply with legal requirements.
                </p>

                <p>
                    Cookies and Similar Technologies: Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could
                    affect certain features or services of our Sites.
                </p>

                <p>
                    Opting out of Email Marketing: You can unsubscribe from our marketing email list at any time by clicking on the unsubscribe link in the emails that we send or by contacting us using the details provided below. You will then be removed from the marketing
                    email list – however, we may still need to send you service-related notifications that are necessary for the administration and use of your account. You can also opt-out by:
                </p>

                <ul>
                    <li>Noting your preferences at the time you register your account with us.</li>
                    <li>Logging into your account settings and updating your preferences.</li>
                    <li>Contacting us using the contact information provided below.</li>
                </ul>

                <h3><strong>11. FOR CALIFORNIA RESIDENTS WITH SPECIFIC PRIVACY RIGHTS </strong></h3>

                <p>If you are a resident of California, you are granted specific rights regarding access to your personal information.</p>

                <p>
                    California Civil Code Section 1798.83, also known as the “Shine The Light” law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any)
                    we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and
                    would like to make such a request, please submit your request in writing to us using the contact information provided below.
                </p>

                <h3><strong>12. UPDATES TO THIS POLICY </strong></h3>

                <p>
                    We may update this privacy policy from time to time. The updated version will be indicated by an updated “Revised” or “Last Updated” date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy
                    policy, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy policy frequently to be informed of how we are protecting your information.
                </p>

                <h3><strong>13. HOW YOU CAN CONTACT US ABOUT THIS POLICY </strong></h3>

                <p>If you have questions or comments about this policy, please email us at <a href="mailto:support@guidewise.io"> support@guidewise.io </a>.</p>

                <p>
                    If you are a resident in the European Economic Area, the "data controller" of your personal information is T.G. AND G.T. WOLF, INC. d/b/a Guidewise You can contact them directly regarding the processing of your information by COMPANY, by email or by post
                    at the address set forth above.
                </p>

                <p>If you have any further questions or comments about us or our policies, please email us at <a href="mailto:support@guidewise.io"> support@guidewise.io </a>.</p>


            </div>
        </div>
    </div>
</div>