<div class="wrapper login-section">
    <!-- ==== loginHeader ===== -->
    <div class="loginHeader">
        <div class="container">
            <a *ngIf="webType!=1"  routerLink="/" class="logo">
                <img src="assets/images/Growthdrivers.svg" />
            </a>
            <div *ngIf="webType==1">
                <app-loginheader></app-loginheader>
            </div>
        </div>
    </div>

    <!-- loginHeader end  -->
    <div class="main_container">
        <div class="container">
            <div class="title-bar">
                <h1><a class="backArrow pointer" (click)="goBack()">FAQs </a></h1>
            </div>
            <!-- <div class="heading">
                <h4 class="extrabold"><a class="backArrow pointer" (click)="goBack()">FAQs </a></h4>
            </div> -->
            <div class="faq" id="accordion">
                <ul>
                    <li>
                        <div class="card">
                            <div class="card-header" id="faqHeading-1">
                                <div class="mb-0">
                                    <h5 class="faq-title" data-toggle="collapse" data-target="#faqCollapse-1" data-aria-expanded="true" data-aria-controls="faqCollapse-1">What is a Growthdriver?<i class="mdi mdi-chevron-down rotate-icon float-right"></i></h5>
                                </div>
                            </div>
                            <div id="faqCollapse-1" class="collapse" aria-labelledby="faqHeading-1" data-parent="#accordion">
                                <div class="card-body">
                                    <p>A Growthdriver is an intelligent folder or database that securely contains rich data on
                                        various components, such as your emotions and rational thoughts. It analyzes the beliefs, emotions,
                                        meanings, and behaviors you've experienced in relation to specific events in your life, providing
                                        constructive feedback to improve your EQ and IQ skills over time. You can log individual entries
                                        within a parent Growthdriver. For instance, if a Growthdriver is created for a recurring event, you
                                        can log entries for each instance of that event within the parent Growthdriver.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li>
                        <div class="card">
                            <div class="card-header" id="faqHeading-2">
                                <div class="mb-0">
                                    <h5 class="faq-title" data-toggle="collapse" data-target="#faqCollapse-2" data-aria-expanded="false" data-aria-controls="faqCollapse-2">What is an Action Item?
                                        <i class="mdi mdi-chevron-down rotate-icon float-right"></i>
                                    </h5>
                                </div>
                            </div>
                            <div id="faqCollapse-2" class="collapse" aria-labelledby="faqHeading-2" data-parent="#accordion">
                                <div class="card-body">
                                    <p> An Action Item is an optional component of a Growthdriver, consisting of short, tangible
                                        tasks to support a Growthdriver. For example, if you create a Growthdriver to reflect on a negative
                                        event, you can create Action Items to help improve the situation. Conversely, for a positive event,
                                        you can create Action Items to reinforce it.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li>
                        <div class="card">
                            <div class="card-header" id="faqHeading-3">
                                <div class="mb-0">
                                    <h5 class="faq-title" data-toggle="collapse" data-target="#faqCollapse-3" data-aria-expanded="false" data-aria-controls="faqCollapse-3"> What is a Goal? <i class="mdi mdi-chevron-down rotate-icon float-right"></i></h5>
                                </div>
                            </div>
                            <div id="faqCollapse-3" class="collapse" aria-labelledby="faqHeading-3" data-parent="#accordion">
                                <div class="card-body">
                                    <p>
                                        A Goal documents a desired future outcome. When creating a Goal, you explore the future
beliefs, emotions, meanings, and behaviors you wish to experience. You can link individual
Growthdrivers to a Goal, which can act as a folder containing Growthdrivers that support the
achievement of that Goal.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li>
                        <div class="card">
                            <div class="card-header" id="faqHeading-4">
                                <div class="mb-0">
                                    <h5 class="faq-title" data-toggle="collapse" data-target="#faqCollapse-4" data-aria-expanded="false" data-aria-controls="faqCollapse-4">What is a Milestone?<i class="mdi mdi-chevron-down rotate-icon float-right"></i></h5>
                                </div>
                            </div>
                            <div id="faqCollapse-4" class="collapse" aria-labelledby="faqHeading-4" data-parent="#accordion">
                                <div class="card-body">
                                    <p>
                                        While Action Items are immediate tasks, Milestones are bigger tasks requiring more time
and effort to support a Goal. For example, for a Goal like &#39;Feel Confident in a Bathing Suit,&#39; you
might create Milestones such as &#39;Lose 20 lbs.&#39; or &#39;Drop Three Pant Sizes.&#39;
                                    </p>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li>
                        <div class="card">
                            <div class="card-header" id="faqHeading-5">
                                <div class="mb-0">
                                    <h5 class="faq-title" data-toggle="collapse" data-target="#faqCollapse-5" data-aria-expanded="false" data-aria-controls="faqCollapse-5"> What is a Project?
                                        <i class="mdi mdi-chevron-down rotate-icon float-right"></i>
                                    </h5>
                                </div>
                            </div>
                            <div id="faqCollapse-5" class="collapse" aria-labelledby="faqHeading-5" data-parent="#accordion">
                                <div class="card-body">
                                    <p>
                                        A Project is a long-term, big-picture Goal. For example, a Goal like &#39;Feel Confident in a
Bathing Suit&#39; can be part of a larger Project like &#39;Improve Self-Confidence&#39; or &#39;Learn to Love
Myself.&#39; You can link individual Goals to a Project, achieving multiple Goals to accomplish the larger
Project.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="card">
                            <div class="card-header" id="faqHeading-6">
                                <div class="mb-0">
                                    <h5 class="faq-title" data-toggle="collapse" data-target="#faqCollapse-6" data-aria-expanded="false" data-aria-controls="faqCollapse-6">How do I create an entry?
                                        <i class="mdi mdi-chevron-down rotate-icon float-right"></i>
                                    </h5>
                                </div>
                            </div>
                            <div id="faqCollapse-6" class="collapse" aria-labelledby="faqHeading-6" data-parent="#accordion">
                                <div class="card-body">
                                    <p>
                                        To create an entry, click on the &#39;CREATE NEW ENTRY&#39; button at the top-right of the web
app screen, then select the entry type. You can also navigate to the desired submodule and click the
plus sign button. In the mobile apps, tap the hovering ‘create’ button at the bottom-right of the
screen. After entering the mandatory information, you can create your entry. To return later, click
the &#39;PENDING&#39; button. You can access created entries in your Created library and saved entries in
your Pending library.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li>
                        <div class="card">
                            <div class="card-header" id="faqHeading-7">
                                <div class="mb-0">
                                    <h5 class="faq-title" data-toggle="collapse" data-target="#faqCollapse-7" data-aria-expanded="false" data-aria-controls="faqCollapse-7">How do I conquer an entry?
                                        <i class="mdi mdi-chevron-down rotate-icon float-right"></i></h5>
                                </div>
                            </div>
                            <div id="faqCollapse-7" class="collapse" aria-labelledby="faqHeading-7" data-parent="#accordion">
                                <div class="card-body">
                                    <p>
                                        After creating an entry, you have documented insights into your behaviors and emotional
reactions. Revisiting these entries helps you consider ways to overcome obstacles or achieve desired
outcomes. Once you feel you've accomplished this, you can mark the entry as &#39;conquered.&#39;
                                    </p>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="card">
                            <div class="card-header" id="faqHeading-8">
                                <div class="mb-0">
                                    <h5 class="faq-title" data-toggle="collapse" data-target="#faqCollapse-8" data-aria-expanded="false" data-aria-controls="faqCollapse-8">What is the Conquered library?<i class="mdi mdi-chevron-down rotate-icon float-right"></i></h5>
                                </div>
                            </div>
                            <div id="faqCollapse-8" class="collapse" aria-labelledby="faqHeading-8" data-parent="#accordion">
                                <div class="card-body">
                                    <p>
                                        The Conquered library in Growthdrivers is a repository for previously conquered entries,
allowing you to review and find inspiration from your past achievements.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="card">
                            <div class="card-header" id="faqHeading-9">
                                <div class="mb-0">
                                    <h5 class="faq-title" data-toggle="collapse" data-target="#faqCollapse-9" data-aria-expanded="false" data-aria-controls="faqCollapse-9">How do I know the emotional intensity that I experience?<i class="mdi mdi-chevron-down rotate-icon float-right"></i></h5>
                                </div>
                            </div>
                            <div id="faqCollapse-9" class="collapse" aria-labelledby="faqHeading-9" data-parent="#accordion">
                                <div class="card-body">
                                    <p>
                                        The intensity measurement is personal and subjective. Your intensity scale may differ from
others, but only you will review your personal data within Growthdrivers. This practice helps you
explore and manage your emotions, strengthening your emotional awareness.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="card">
                            <div class="card-header" id="faqHeading-10">
                                <div class="mb-0">
                                    <h5 class="faq-title" data-toggle="collapse" data-target="#faqCollapse-10" data-aria-expanded="false" data-aria-controls="faqCollapse-10">What is a Growthmantra?<i class="mdi mdi-chevron-down rotate-icon float-right"></i></h5>
                                </div>
                            </div>
                            <div id="faqCollapse-10" class="collapse" aria-labelledby="faqHeading-10" data-parent="#accordion">
                                <div class="card-body">
                                    <p>
                                        A Growthmantra is an auditory tool that provides a different perspective on your
                                        Growthdriver and Goal entries. By converting aspects of an entry into an audio file, you can analyze
                                        your thoughts, feelings, and actions through another voice, helping to overcome personal biases.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li>
                        <div class="card">
                            <div class="card-header" id="faqHeading-11">
                                <div class="mb-0">
                                    <h5 class="faq-title" data-toggle="collapse" data-target="#faqCollapse-11" data-aria-expanded="false" data-aria-controls="faqCollapse-11">How do I create a Growthmantra?
                                        <i class="mdi mdi-chevron-down rotate-icon float-right"></i>
                                    </h5>
                                </div>
                            </div>
                            <div id="faqCollapse-11" class="collapse" aria-labelledby="faqHeading-11" data-parent="#accordion">
                                <div class="card-body">
                                    <p>
                                        To create a Growthmantra, first create a Growthdriver or Goal entry. In the mobile app,
navigate to the Mantras module, tap the hovering ‘create’ button at the bottom-right, and select
the desired entry. Choose the specific content to include, select a voice to read it, and enter a title.
Manage your Growthmantras through both the web and mobile apps.  
                                    </p>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li>
                        <div class="card">
                            <div class="card-header" id="faqHeading-12">
                                <div class="mb-0">
                                    <h5 class="faq-title" data-toggle="collapse" data-target="#faqCollapse-12" data-aria-expanded="false" data-aria-controls="faqCollapse-12">What is a Growthmantra Playlist?<i class="mdi mdi-chevron-down rotate-icon float-right"></i></h5>
                                </div>
                            </div>
                            <div id="faqCollapse-12" class="collapse" aria-labelledby="faqHeading-12" data-parent="#accordion">
                                <div class="card-body">
                                    <p>
                                        A Growthmantra Playlist is a collection of individual Growthmantras grouped together,
similar to a music playlist. This allows you to organize related Growthmantras in one convenient
location.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li>
                        <div class="card">
                            <div class="card-header" id="faqHeading-13">
                                <div class="mb-0">
                                    <h5 class="faq-title" data-toggle="collapse" data-target="#faqCollapse-13" data-aria-expanded="false" data-aria-controls="faqCollapse-13">What is an Observation?<i class="mdi mdi-chevron-down rotate-icon float-right"></i></h5>
                                </div>
                            </div>
                            <div id="faqCollapse-13" class="collapse" aria-labelledby="faqHeading-13" data-parent="#accordion">
                                <div class="card-body">
                                    <p>
                                        An Observation is a data-driven, collaborative feedback form designed to intelligently
gather and analyze EQ and IQ data from stakeholders. You can use Observations for various
subjects, such as gathering feedback on a leadership training program, generating ideas for new
marketing campaigns, or obtaining customer reviews on your latest product release. The primary
objective of Observations is to provide you with the tools to gauge stakeholder thoughts and
emotions constructively and insightfully.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li>
                        <div class="card">
                            <div class="card-header" id="faqHeading-14">
                                <div class="mb-0">
                                    <h5 class="faq-title" data-toggle="collapse" data-target="#faqCollapse-14" data-aria-expanded="false" data-aria-controls="faqCollapse-14">How do I create an Observation?
                                        <i class="mdi mdi-chevron-down rotate-icon float-right"></i>
                                    </h5>
                                </div>
                            </div>
                            <div id="faqCollapse-14" class="collapse" aria-labelledby="faqHeading-14" data-parent="#accordion">
                                <div class="card-body">
                                    <p>
                                        To create an Observation, simply navigate to the Observations submodule within the
Review module of the Growthdrivers web app. From there, you can publish and distribute
Observations to stakeholders through various methods, including CSV files, unique hyperlinks, and
more. Currently, Observations can only be created and managed through the Growthdrivers web
app.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="card">
                            <div class="card-header" id="faqHeading-25">
                                <div class="mb-0">
                                    <h5 class="faq-title" data-toggle="collapse" data-target="#faqCollapse-25" data-aria-expanded="false" data-aria-controls="faqCollapse-25">How is data security handled for Observations?
                                        <i class="mdi mdi-chevron-down rotate-icon float-right"></i>
                                    </h5>
                                </div>
                            </div>
                            <div id="faqCollapse-25" class="collapse" aria-labelledby="faqHeading-25" data-parent="#accordion">
                                <div class="card-body">
                                    <p>Data security is a top priority. Accordingly, you can create Observations with such security controls as password
                                    protection, expiration thresholds, and many more.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="card">
                            <div class="card-header" id="faqHeading-15">
                                <div class="mb-0">
                                    <h5 class="faq-title" data-toggle="collapse" data-target="#faqCollapse-15" data-aria-expanded="false" data-aria-controls="faqCollapse-15">What is the Leaderboard?
                                        <i class="mdi mdi-chevron-down rotate-icon float-right"></i>
                                    </h5>
                                </div>
                            </div>
                            <div id="faqCollapse-15" class="collapse" aria-labelledby="faqHeading-15" data-parent="#accordion">
                                <div class="card-body">
                                    <p>
                                        The leaderboard displays competition information among users within your organization’s
Growthdrivers account. Users earn points for interactions and can be part of various teams,
collectively earning points for tasks completed in the Growthdrivers application.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li>
                        <div class="card">
                            <div class="card-header" id="faqHeading-16">
                                <div class="mb-0">
                                    <h5 class="faq-title" data-toggle="collapse" data-target="#faqCollapse-16" data-aria-expanded="false" data-aria-controls="faqCollapse-16">How do I earn points?<i class="mdi mdi-chevron-down rotate-icon float-right"></i></h5>
                                </div>
                            </div>
                            <div id="faqCollapse-16" class="collapse" aria-labelledby="faqHeading-16" data-parent="#accordion">
                                <div class="card-body">
                                    <p>
                                        Earn points by completing interactions and tasks within Growthdrivers. Team members
collectively earn points through system interactions. Points are awarded for creating entries,
conquering entries, composing Growthmantras, listening to Growthmantras, and more. Interactions
also earn badges.

                                    </p>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="card">
                            <div class="card-header" id="faqHeading-17">
                                <div class="mb-0">
                                    <h5 class="faq-title" data-toggle="collapse" data-target="#faqCollapse-17" data-aria-expanded="false" data-aria-controls="faqCollapse-17">How do I earn badges?<i class="mdi mdi-chevron-down rotate-icon float-right"></i></h5>
                                </div>
                            </div>
                            <div id="faqCollapse-17" class="collapse" aria-labelledby="faqHeading-17" data-parent="#accordion">
                                <div class="card-body">
                                    <p>
                                        Earn badges by completing interactions and objectives within Growthdrivers. Points and
badges are correlated: the more points you earn, the more badges you accumulate. View badges in
the Badges submodule.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="card">
                            <div class="card-header" id="faqHeading-18">
                                <div class="mb-0">
                                    <h5 class="faq-title" data-toggle="collapse" data-target="#faqCollapse-18" data-aria-expanded="false" data-aria-controls="faqCollapse-18">How do I create and manage teams within Growthdrivers?<i class="mdi mdi-chevron-down rotate-icon float-right"></i></h5>
                                </div>
                            </div>
                            <div id="faqCollapse-18" class="collapse" aria-labelledby="faqHeading-18" data-parent="#accordion">
                                <div class="card-body">
                                    <p>
                                        Engage your organization by creating teams in Growthdrivers. Admins and Super Admins
can access the Team Management submodule within the admin console to create and manage
teams. Enter required information, add users, and click CREATE TEAM. Manage teams by selecting
the desired team and using the three-dot menu to edit.

                                    </p>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li>
                        <div class="card">
                            <div class="card-header" id="faqHeading-19">
                                <div class="mb-0">
                                    <h5 class="faq-title" data-toggle="collapse" data-target="#faqCollapse-19" data-aria-expanded="false" data-aria-controls="faqCollapse-19">How do I onboard members into my organization’s Growthdrivers account?<i class="mdi mdi-chevron-down rotate-icon float-right"></i></h5>
                                </div>
                            </div>
                            <div id="faqCollapse-19" class="collapse" aria-labelledby="faqHeading-19" data-parent="#accordion">
                                <div class="card-body">
                                    <p>
                                        Onboard members by navigating to the Member Management submodule within the
admin console. Click the ‘create’ plus sign button, add email addresses, and click INVITE. For large
groups, use the Invitation Queue feature. Ensure successful delivery by copying a unique invitation
hyperlink for each user.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li>
                        <div class="card">
                            <div class="card-header" id="faqHeading-20">
                                <div class="mb-0">
                                    <h5 class="faq-title" data-toggle="collapse" data-target="#faqCollapse-20" data-aria-expanded="false" data-aria-controls="faqCollapse-20">What user roles exist within the Growthdrivers application?<i class="mdi mdi-chevron-down rotate-icon float-right"></i></h5>
                                </div>
                            </div>
                            <div id="faqCollapse-20" class="collapse" aria-labelledby="faqHeading-20" data-parent="#accordion">
                                <div class="card-body">
                                    <p>
                                        There are three user roles: Member, Admin, and Super Admin. Only Admins and Super
Admins can access the admin console. Updating a user role is quick and can be done through the
admin center.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li>
                        <div class="card">
                            <div class="card-header" id="faqHeading-21">
                                <div class="mb-0">
                                    <h5 class="faq-title" data-toggle="collapse" data-target="#faqCollapse-21" data-aria-expanded="false" data-aria-controls="faqCollapse-21">Am I able to generate reports within the Growthdrivers application?<i class="mdi mdi-chevron-down rotate-icon float-right"></i></h5>
                                </div>
                            </div>
                            <div id="faqCollapse-21" class="collapse" aria-labelledby="faqHeading-21" data-parent="#accordion">
                                <div class="card-body">
                                    <p>
                                        Yes, Growthdrivers provides metrics, analytics, KPIs, and reports for real-time awareness of
your organization’s EQ and IQ data. Reports can be generated at individual, team, and organization
levels, depending on your user role.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li>
                        <div class="card">
                            <div class="card-header" id="faqHeading-22">
                                <div class="mb-0">
                                    <h5 class="faq-title" data-toggle="collapse" data-target="#faqCollapse-22" data-aria-expanded="false" data-aria-controls="faqCollapse-22">If I need additional assistance, what should I do?<i class="mdi mdi-chevron-down rotate-icon float-right"></i></h5>
                                </div>
                            </div>
                            <div id="faqCollapse-22" class="collapse" aria-labelledby="faqHeading-22" data-parent="#accordion">
                                <div class="card-body">
                                    <p>
                                        For additional assistance, we encourage you to visit our Support submodule in the
Growthdrivers application or send a message to our support team through the following link:
<a class="blue" href="https://www.guidewise.io/support/">https://www.guidewise.io/support/</a>. Additionally, you can also email our support team directly by
reaching out to <a class="blue" href="support@guidewise.io">support@guidewise.io</a>. Submissions are private and secure, and our friendly support
team is ready to help.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </li>

                    <div class="clear"></div>
                </ul>
            </div>
            <div class="reach_tous">
                <p>For additional information, please contact us at <a href="mailto:info@guidewise.io">info@guidewise.io</a>.</p>
            </div>
        </div>

        <div class="footerWrap">
            <div class="copyright">
                <a class="_logo_a mt-2" href="https://www.guidewise.io/" target="_blank" rel="noopener noreferrer">
                    <img class="_logo" src="../../../assets/images/guidewise_primary mark_final.jpg">
                </a>
                <span>Growthdrivers R3.0.0</span>
                <p>&copy; 2021 - 2025 Guidewise</p>
            </div>
        </div>

    </div>