import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { SliderComponent } from './slider/slider.component';
import { Location } from '@angular/common';
@Component({
  selector: 'app-emotion-intensity',
  templateUrl: './emotion-intensity.component.html',
  styleUrls: ['./emotion-intensity.component.scss']
})
export class EmotionIntensityComponent implements OnInit {

  @Input() existingTitle: [];
  finalIntensityData = [];
  @Input() emotionDataForIntensity: [];
  @Input() emotionType: string;
  @Input() myCommonArrEmotions: [];
  @Output() emotionValues = new EventEmitter<any>();
  @Input() notPas;
  @ViewChildren('sliderRef') sliderRef: QueryList<SliderComponent>;

  constructor(private location: Location) { }

  ngOnInit(): void {
    let EmotionData = localStorage.getItem("clearEmotion");   
    if(EmotionData){
        this.myCommonArrEmotions=[];
    }
    console.log(this.myCommonArrEmotions, "==========");
    console.log(this.emotionDataForIntensity);
    console.log(this.emotionType, "emotionType");

  }
  goBack() {
    this.emotionValues.emit([-2, null]);
    localStorage.removeItem("clearEmotion");  
  }
  showFinalPage() {   
    this.finalIntensityData = this.prepareIntensityData();
    if (+this.emotionType > 0) {
      this.emotionValues.emit([0, this.finalIntensityData, this.emotionType]); // if its u-turn then return for past/future respectively.
    } else {
      this.emotionValues.emit([0, this.finalIntensityData]);      
    }
    localStorage.removeItem("clearEmotion");  
  }
  showPreviousPage() {
    this.finalIntensityData = this.prepareIntensityData();
    if (+this.emotionType > 0) {
      this.emotionValues.emit([-2, this.finalIntensityData, this.emotionType]); // if its u-turn then return for past/future respectively.
    } else {
      this.emotionValues.emit([-2, this.finalIntensityData]);
    }
  }
  clearAllData() {
    this.sliderRef.forEach((e) => {
      e.hourCount = 0;
      e.minuteCount = 0;
      e.dayCount = 0;
      e.myValue = 5.0;
    });
  }

  prepareIntensityData() {
    let tempArr = [];
    this.sliderRef.forEach((e) => {
      let myvalue;
      let result = (e.myValue - Math.floor(e.myValue)) !== 0; 
      if(result){
        myvalue = e.myValue;
      }else{
        myvalue =  Number(e.myValue)
        myvalue = myvalue +'.0' 
      }
      let myObj = {
        emotiontype: this.emotionType == '1' ? 'past' : 'future',
        emotiontypeId: this.emotionType,
        name: e.intensityData.name,
        emotion_id: e.intensityData.id,
        intensity: myvalue,
        day: e.dayCount,
        hours: e.hourCount,
        minutes: e.minuteCount,
        color_code: e.intensityData.color_code
      }
      myvalue="";
      tempArr.push(myObj);

    });
    return tempArr;
  }
}
