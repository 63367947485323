import { Injectable } from '@angular/core';
import { HttpClient, HttpParams,HttpHeaders } from '@angular/common/http';
import swal from "sweetalert2";
import { Router } from '@angular/router';
import { generalModel, icons, menu, BtnText, permission, tabArray, optionArray } from '../shared/models/general.model';
import { BehaviorSubject, Subject } from 'rxjs';
import { DataShareService } from 'src/app/services/data-share.service';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  public idleTimer: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public idleTimershow: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public pinChange: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public loaderText: BehaviorSubject<string> = new BehaviorSubject<string>('');
  showLoader: boolean = true;
  icon = icons;
  menu = menu;
  btnText = BtnText;
  generalModel = new generalModel();
  Permission = permission;
  public _albums = [];
  private tempArrSorted = [];
  job_fun = [ 
    "Administration/Operations",
    "Services/Customer Support",
    "Marketing",
    "Sales",
    "Production",
    "Information Technology",
    "Legal",
    "Human Resources",
    "Finance/Accounting",
    "Engineering/R & D",
    "Purchasing",
    "Other"]
  constructor(private http: HttpClient, private router: Router, private dataSharingService: DataShareService,) {
  }

  getTempArrSortedArr(){
    return this.tempArrSorted;
  }

  setTempSortedArr(data:string){
    this.tempArrSorted.push(data)
  }

  getData(url, param) {
    return this.http.get<any>(url, { params: param });
  }
  getDatas(url) {
    return this.http.get<any>(url);
  }
  getDetail(url, param) {
    return this.http.get<any>(url, {});
  }


  putData(url, value) {
    return this.http.put<any>(url, value);
  }

  postData(url, value) {
    return this.http.post<any>(url, value);
  }

  updateData(url, value) {
    return this.http.put<any>(url, value);
  }
  patchData(url, value) {
    return this.http.patch<any>(url, value);
  }

  deleteData(url, param) {
    return this.http.delete<any>(url, { params: param });
  }

  postUpdate(url, value) {
    return this.http.put<any>(url, value);
  }


  showAlert(title: string, text: string) {
    swal.fire({
      title: ' ' + title,
      text: text,
      showConfirmButton: false,
      position: 'top',
      timer: 5000
    })
  }

  errorAlert(text, status) {
     swal.fire({
      toast: true,
      title: text,
       timer: 5000,
      position: 'top',
      background: '#ff5242',
      showConfirmButton: false,
      icon:"error"
    }).then((result) => {
      if (status) {
        localStorage.removeItem('adminToken');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('adminToken');
        localStorage.removeItem('firstName');
        localStorage.removeItem('lastName');
        localStorage.removeItem('type-redirect');
        localStorage.removeItem('image');
        localStorage.removeItem('permissionData');
        localStorage.removeItem('notification');
        localStorage.removeItem('allMantra');
        localStorage.removeItem('favourites');
        this.router.navigate(['/login']);

      }
    })
  }

 
  toast1(title, text) {   
    swal.fire({
      toast: true,
      position: 'top',
      showConfirmButton: false,
      timer: 5000,
      background: '#5ECB5E',
      title: ' ' + title,
      text: text
    })
  }


  toast(title, text) {
    const toast = swal.mixin({
      toast: true,
      position: 'top',
      showConfirmButton: false,
      timer: 5000,
      background: '#5ECB5E',
      icon: "success",
    });
    toast.fire({
      title: ' ' + title,
      text: text
    })
  }
  errorToast(title, text) {
    const toast = swal.mixin({
      toast: true,
      position: 'top',
      showConfirmButton: false,
      timer: 5000,
      background: '#ff5242 ',
     
    });
    toast.fire({
      icon:"error",
      title: '' + title,
      text: text,
    })
  }

  errorAlert1(title, text) {
    swal.fire({
      title: '' + text,
      // text: text,
      icon:"error",
      toast: true,
      timer: 5000,
      position: 'top',
      background: '#ff5242',
      showConfirmButton: false,
    }).then((result) => {
      if (title) { }
    })
  }

  toastAlert(title, text) {
    const toast = swal.mixin({
      toast: true,
      position: 'top',
      showConfirmButton: false,
      timer: 5000,   
      background: '#ff5242',
      icon: "warning",
    });
    toast.fire({
      title: ' ' + title,
      text: text
    })
  }


/*upload */




  // Show and hide Loader
  private loaderSubject = new Subject<Boolean>();
  loaderState = this.loaderSubject.asObservable();
  loader(value) { this.loaderSubject.next(value); }

  setdata(data) {
    for (let x of tabArray) {
      let array1 = {}
      for (let y of x.value) {
        array1[optionArray[y].name] = (data[x.name] == undefined || data[x.name][optionArray[y].name] == undefined ? false : data[x.name][optionArray[y].name]);
      }
      permission[x.name] = array1;
    }
    permission['status'] = "updated";
    return true;
  }
  startTimer(val) {
    console.log(val)
    this.idleTimer.next(val)
  }

  showTimeout(val) {
    console.log(val)
    this.idleTimershow.next(val)
  }

  disablePin(val) {
    console.log(val)
    this.pinChange.next(val)
  }


  loadingText(message) {
    this.loaderText.next(message)
  }
}
