import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
//import { LoginResponse } from './login-response';

import { apiEndPoint } from '../../shared/apiEndPoints';
import { AdminService } from '../../services/admin.service';
import { loginModel } from '../../shared/models/login-model';
import { NotificationService } from '../../../app/dashboard/notification/notification.service';
import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-login',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.scss']
})
export class TermsConditionsComponent implements OnInit {
  public loading = false;
  isRemember: boolean = false;
  emailWarningMsg: string;
  passwordWarningMsg: string;
  emailStatus: string;
  passwordStatus: string;
  commonMsg: string;
  notificationCount:any;
  currentUrl: string;
  subscription: Subscription;
  loginForm: FormGroup = null;
  loginModel = new loginModel();
  passwordType: string = 'password';
  passwordShown: boolean = false;
  userNotFound: boolean = false;
  webType:any;
  constructor(private notificationService : NotificationService,private readonly authService: AuthService, private readonly router: Router, private api: AdminService,  private route: ActivatedRoute) {
    this.route.queryParams.subscribe(params => {
      this.webType = params['web'];
    });
  }

  ngOnInit(): void {

    this.loginForm = new FormGroup({
      email: new FormControl('', [Validators.required]),
      password: new FormControl('', Validators.required),
      checkbox: new FormControl(false)
    })

    

  }

  
  

 

}


