<div class="wrapper emotion_intensity">
    <div class="new-entry-container nopadding-mob">
        <div class="title-bar fixingHeading differ">
            <div class="leftHeading">
                <h4><a (click)="showPreviousPage()" class="backArrow">Select Intensity</a></h4>
                <p *ngIf="!notPas" class="sub_title paddingTop">
                    <strong>{{emotionType == '1'? 'Past ': 'Future'}} Emotions</strong> Indicate
                    the intensity experienced for each selected emotion.
                </p>
                <p *ngIf="notPas == 'true'" class="sub_title">
                    <span>Select the intensity for each selected emotion.</span>
                </p>
            </div>
            <h1 *ngIf="existingTitle"  class="pos">{{existingTitle}}</h1>
            
        </div>
        <div class="clear"></div>
        <div class="whiteBox">
            <div class="listWrap">
                <ng-container *ngFor="let data of emotionDataForIntensity; let i = index">
                    <app-slider [forEditIntensity]="myCommonArrEmotions" #sliderRef [emotionType]="emotionType" [intensityData]="data" [index]="i"></app-slider>
                </ng-container>
            </div>
            <div class="footerwrap pt-4">
                <div class="actionbox-rt">
                    <a class="previousBtn commonBtn button_padding" (click)="showPreviousPage()"> Previous</a>
                    <a class="commonBtn clear_btn button_padding" (click)="clearAllData()">Clear </a>
                    <a class="nextBtn commonBtn button_padding" (click)="showFinalPage()"> Next </a>
                </div>
                <div class="clear"></div>
            </div>
        </div>
    </div>
</div>