import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private readonly authService: AuthService, private readonly router: Router) {

  }
  // canActivate(
  //   next: ActivatedRouteSnapshot,
  //   state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
  //   if (this.authService.isAuthenticated()) {
  //     return this.authService.isAuthenticated()
  //   } else {
  //     this.router.navigate(['/login']);
  //   }
  // }
  canActivate()  {
    let token = localStorage.getItem("access_token");   
    let gdUserInfo = JSON.parse(localStorage.getItem("gdUserInfo"));   
    if (token && gdUserInfo.profile_status == 1) {
      if(!localStorage.getItem("role")) {
        localStorage.setItem('role',JSON.stringify(gdUserInfo.role))
        localStorage.setItem('user_type',JSON.stringify(gdUserInfo.user_type))
      }
      return true;
    }   
    this.router.navigate(['']);   
    return false;
  }

}
