import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-privacy-policy-view',
  templateUrl: './privacy-policy-view.component.html',
  styleUrls: ['./privacy-policy-view.component.scss']
})
export class PrivacyPolicyViewComponent implements OnInit {
  showBack: boolean = true;
  @Input() back;
  constructor(private readonly router: Router,
    private location: Location,
    ) { }

  ngOnInit(): void {
    this.showBack = !this.back;
  }

  goBack() {
    this.location.back();
  }
}
