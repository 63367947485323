<ul>
    <li style="width: 100%;">
        <!-- <p [class.invisible]="index>0"><strong>{{emotionType == '1'? 'Past ': 'Future '}} Emotions</strong> Indicate the intensity experienced for each selected emotion.
        </p> -->
        <div class="common_row leftRow" style="border-right:1px solid #E2E5EB">
            <div class="sliderWrap">
                <div class="ingnored"> <span [ngStyle]="{'background':intensityData.color_code=='#8F0000' ? '#c27676':intensityData.color_code }">{{intensityData.name}}</span> </div>
                <div class="sliderWrap">
                    <mat-slider thumbLabel [value]="myValue" [displayWith]="formatLabel" (change)="getSliderValue($event)" min="0" max="10" step="0.1">
                    </mat-slider>
                </div>
            </div>
        </div>
    </li>
    <!-- <li>
        <p [class.invisible]="index>0"> Indicate the duration experienced for each selected emotion.</p>

        <div class="common_row rightRow">
            <div class="countWrap">
                <div class="number">
                    <a  class="minus" (click)="dayCount > 0? dayCount = dayCount-1: 0">-</a>
                    <input type="number"  (ngModelChange)="dayChanged($event)" (keyup)="dayInput($event)" [(ngModel)]="dayCount" class="quantity" [value]="dayCount">
                    <a  class="plus" (click)="dayCount+1<999? dayCount = dayCount+1: 999">+</a>
                    <span class="text">Days</span>
                </div>
                <div class="clear"></div>

                <div class="number">
                    <a  class="minus" (click)="hourCount > 0? hourCount = hourCount-1: 0">-</a>
                    <input type="number"  (ngModelChange)="hourChanged($event)" (keyup)="hourInput($event)" [(ngModel)]="hourCount" class="quantity">
                    <a  class="plus" (click)="hourCount < 23? hourCount = hourCount+1: 23">+</a>
                    <span class="text">Hours</span>
                </div>
                <div class="clear"></div>

                <div class="number borderRight_none">
                    <a  class="minus" (click)="minuteCount > 0? minuteCount = minuteCount-1: 0">-</a>
                    <input type="number"  (ngModelChange)="minuteChanged($event)" (keyup)="minuteInput($event)" [(ngModel)]="minuteCount" class="quantity" [value]="minuteCount">
                    <a  class="plus" (click)="minuteCount < 59? minuteCount = minuteCount+1: 59">+</a>
                    <span class="text">Minutes</span>
                </div>
                <div class="clear"></div>

            </div>
        </div>
    </li> -->
</ul>