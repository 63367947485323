import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,  Router,NavigationEnd, NavigationStart } from '@angular/router';
import { environment } from 'src/environments/environment';
import { setTheme } from 'ngx-bootstrap/utils';

import { LocationStrategy } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { filter } from 'rxjs/operators';  
import { NavigationService } from './services/navigation.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit  {
  urlHistory: any = [];

  constructor(private location: LocationStrategy, private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private navigat: NavigationService) {
      this.navigat.startSaveHistory();
    history.pushState(null, null, window.location.href);
    this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
    });
    setTheme('bs4');

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
  }

    
  ngOnInit() {
    this.router.events.pipe(  
        filter(event => event instanceof NavigationEnd),  
      ).subscribe(() => {  
        const rt = this.getChild(this.activatedRoute);  
        rt.data.subscribe(data => {  
          this.titleService.setTitle(data.title? data.title:"Growthdrivers")});  
      });

     
  }  
  
  getChild(activatedRoute: ActivatedRoute) {  
    if (activatedRoute.firstChild) {  
      return this.getChild(activatedRoute.firstChild);  
    } else {  
      return activatedRoute;  
    }  
  
  }  
}
