import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AdminService } from 'src/app/services/admin.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  baseUrl: string = environment.backend.baseURL;
  constructor(private readonly http: HttpClient, private readonly admin: AdminService,) { }


  getnotificationData() {
    const httpOptions = {
      params: new HttpParams()
        .set('access_token', localStorage.getItem('access_token'))
        .set('read_all', '1'),
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    }

    return this.http.get<any>(`${this.baseUrl}/api/v1/notifications`, httpOptions);
  }

  deleteNotification() {
    // const httpOptions = {
    //   params: new HttpParams().set('access_token', localStorage.getItem('access_token')),
    //   headers: new HttpHeaders({ 
    //     'Content-Type':'application/json',
    //   })
    // }
    let data = {
      "access_token": localStorage.getItem('access_token'),
    };

    return this.http.post<any>(`${this.baseUrl}/api/v1/notifications/clear`, data);
  }



}